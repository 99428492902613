<template>
  <div>
    <div class="page">
      <div class="CoV_Moderna" v-if="type == 'CoV_Moderna'">
        <div class="t0">
          <span class="t01">#{{ citizen.報到號碼 }}</span>
          <span class="t02">{{
            dayjs(citizen.報到時間).format("MM/DD HH:mm")
          }}</span>
        </div>
        <div class="t1">
          <span class="t11">{{ citizen.姓名 }}</span>
          <span class="t12">{{ citizen.身分證字號 }}</span>
        </div>
        <div class="t2">
          <span class="t21">{{ birth.年 }}</span>
          <span class="t22">{{ birth.月 }}</span>
          <span class="t23">{{ birth.日 }}</span>
          <span class="t24">{{ citizen.電話 }}</span>
        </div>
        <div class="t3">
          <span class="t31">{{ citizen.縣市 }}</span>
          <span class="t32">{{
            citizen.行政區域 || citizen.行政區 || ""
          }}</span>
          <span class="t33">{{ citizen.地址 || "" }}</span>
        </div>
        <div class="t4">
          <span class="t41">{{ dayjs().format("YYYY") }}</span>
          <span class="t42">{{ dayjs().format("MM") }}</span>
          <span class="t43">{{ dayjs().format("DD") }}</span>
        </div>
        <div class="t5">
          <span class="t51">{{
            $store.state.hospitalCodes[citizen.接種地點] || ""
          }}</span>
        </div>
        <div class="tk">
          <span class="tk1">
            學校名稱：<span class="underline">&nbsp;</span>
            <span class="unit">年</span>
            <span class="unit">班</span>
            <span class="unit">號</span>
          </span>
          <span class="tk2"
            >接種劑別：
            <span class="underline text-center"
              >第 {{ citizen.接種劑別 }} 劑</span
            >
          </span>
          <span class="tk3"
            >接種地點：<span class="text-center"
              >校園統一接種 / 衛生所 / 合約醫療院所</span
            >
          </span>
        </div>
      </div>
      <div class="CoV_BioNTech" v-if="type == 'CoV_BioNTech'">
        <div class="t0">
          <span class="t01">#{{ citizen.報到號碼 }}</span>
          <span class="t02">{{
            dayjs(citizen.報到時間).format("MM/DD HH:mm")
          }}</span>
        </div>
        <div class="t1">
          <span class="t11">{{ citizen.姓名 }}</span>
          <span class="t12">{{ citizen.身分證字號 }}</span>
        </div>
        <div class="t2">
          <span class="t21">{{ birth.年 }}</span>
          <span class="t22">{{ birth.月 }}</span>
          <span class="t23">{{ birth.日 }}</span>
          <span class="t24">{{ citizen.電話 }}</span>
        </div>
        <div class="t3">
          <span class="t31">{{ citizen.縣市 }}</span>
          <span class="t32">{{
            citizen.行政區域 || citizen.行政區 || ""
          }}</span>
          <span class="t33">{{ citizen.地址 || "" }}</span>
        </div>
        <div class="t4">
          <span class="t41">{{ dayjs().format("YYYY") }}</span>
          <span class="t42">{{ dayjs().format("MM") }}</span>
          <span class="t43">{{ dayjs().format("DD") }}</span>
        </div>
        <div class="t5">
          <span class="t51">{{
            $store.state.hospitalCodes[citizen.接種地點] || ""
          }}</span>
        </div>
        <div class="tk">
          <span class="tk1">
            學校名稱：<span class="underline">&nbsp;</span>
            <span class="unit">年</span>
            <span class="unit">班</span>
            <span class="unit">號</span>
          </span>
          <span class="tk2"
            >接種劑別：
            <span class="underline text-center"
              >第 {{ citizen.接種劑別 }} 劑</span
            >
          </span>
          <span class="tk3"
            >接種地點：<span class="text-center"
              >校園統一接種 / 衛生所 / 合約醫療院所</span
            >
          </span>
        </div>
      </div>
      <div class="CoV_AZ" v-if="type == 'CoV_AZ'">
        <div class="t0">
          <span class="t01">#{{ citizen.報到號碼 }}</span>
          <span class="t02">{{
            dayjs(citizen.報到時間).format("MM/DD HH:mm")
          }}</span>
        </div>
        <div class="t1">
          <span class="t11">{{ citizen.姓名 }}</span>
          <span class="t12">{{ citizen.身分證字號 }}</span>
        </div>
        <div class="t2">
          <span class="t21">{{ birth.年 }}</span>
          <span class="t22">{{ birth.月 }}</span>
          <span class="t23">{{ birth.日 }}</span>
          <span class="t24">{{ citizen.電話 }}</span>
        </div>
        <div class="t3">
          <span class="t31">{{ citizen.縣市 }}</span>
          <span class="t32">{{
            citizen.行政區域 || citizen.行政區 || ""
          }}</span>
          <span class="t33">{{ citizen.地址 || "" }}</span>
        </div>
        <div class="t4">
          <span class="t41">{{ dayjs().format("YYYY") }}</span>
          <span class="t42">{{ dayjs().format("MM") }}</span>
          <span class="t43">{{ dayjs().format("DD") }}</span>
        </div>
        <div class="t5">
          <span class="t51">{{
            $store.state.hospitalCodes[citizen.接種地點] || ""
          }}</span>
        </div>
      </div>
      <div class="CoV_Medigen" v-if="type == 'CoV_Medigen'">
        <div class="t0">
          <span class="t01">#{{ citizen.報到號碼 }}</span>
          <span class="t02">{{
            dayjs(citizen.報到時間).format("MM/DD HH:mm")
          }}</span>
        </div>
        <div class="t1">
          <span class="t11">{{ citizen.姓名 }}</span>
          <span class="t12">{{ citizen.身分證字號 }}</span>
        </div>
        <div class="t2">
          <span class="t21">{{ birth.年 }}</span>
          <span class="t22">{{ birth.月 }}</span>
          <span class="t23">{{ birth.日 }}</span>
          <span class="t24">{{ citizen.電話 }}</span>
        </div>
        <div class="t3">
          <span class="t31">{{ citizen.縣市 }}</span>
          <span class="t32">{{
            citizen.行政區域 || citizen.行政區 || ""
          }}</span>
          <span class="t33">{{ citizen.地址 || "" }}</span>
        </div>
        <div class="t4">
          <span class="t41">{{ dayjs().format("YYYY") }}</span>
          <span class="t42">{{ dayjs().format("MM") }}</span>
          <span class="t43">{{ dayjs().format("DD") }}</span>
        </div>
        <div class="t5">
          <span class="t51">{{
            $store.state.hospitalCodes[citizen.接種地點] || ""
          }}</span>
        </div>
      </div>
      <div class="CoV_Novavax" v-if="type == 'CoV_Novavax'">
        <div class="t0">
          <span class="t01">#{{ citizen.報到號碼 }}</span>
          <span class="t02">{{
            dayjs(citizen.報到時間).format("MM/DD HH:mm")
          }}</span>
        </div>
        <div class="t1">
          <span class="t11">{{ citizen.姓名 }}</span>
          <span class="t12">{{ citizen.身分證字號 }}</span>
        </div>
        <div class="t2">
          <span class="t21">{{ birth.年 }}</span>
          <span class="t22">{{ birth.月 }}</span>
          <span class="t23">{{ birth.日 }}</span>
          <span class="t24">{{ citizen.電話 }}</span>
        </div>
        <div class="t3">
          <span class="t31">{{ citizen.縣市 }}</span>
          <span class="t32">{{
            citizen.行政區域 || citizen.行政區 || ""
          }}</span>
          <span class="t33">{{ citizen.地址 || "" }}</span>
        </div>
        <div class="t4">
          <span class="t41">{{ dayjs().format("YYYY") }}</span>
          <span class="t42">{{ dayjs().format("MM") }}</span>
          <span class="t43">{{ dayjs().format("DD") }}</span>
        </div>
        <div class="t5">
          <span class="t51">{{
            $store.state.hospitalCodes[citizen.接種地點] || ""
          }}</span>
        </div>
      </div>
      <div
        class="CoV_bModerna"
        v-if="
          type == 'CoV_bModerna_BA1' ||
            type == 'CoV_bModerna_BA4/5' ||
            type == 'CoV_bModerna_BA4/5-Booster' ||
            type == 'CoV_Moderna_XBB' ||
            type == 'CoV_Novavax_XBB'
        "
      >
        <div class="t0">
          <span class="t01">#{{ citizen.報到號碼 }}</span>
          <span class="t02">{{
            dayjs(citizen.報到時間).format("MM/DD HH:mm")
          }}</span>
        </div>
        <div class="t1">
          <span class="t11">{{ citizen.姓名 }}</span>
          <span class="t12">{{ citizen.身分證字號 }}</span>
        </div>
        <div class="t2">
          <span class="t21">{{ birth.年 }}</span>
          <span class="t22">{{ birth.月 }}</span>
          <span class="t23">{{ birth.日 }}</span>
          <span class="t24">{{ citizen.電話 }}</span>
        </div>
        <div class="t3">
          <span class="t31">{{ citizen.縣市 }}</span>
          <span class="t32">{{
            (citizen.行政區域 || citizen.行政區 || "")
              .replace("臺南市", "")
              .replace("台南市", "")
          }}</span>
          <span class="t33">{{ citizen.地址 || "" }}</span>
        </div>
        <div class="t4">
          <span class="t41">{{ dayjs().format("YYYY") }}</span>
          <span class="t42">{{ dayjs().format("MM") }}</span>
          <span class="t43">{{ dayjs().format("DD") }}</span>
        </div>
        <div class="t5">
          <span class="t51">{{
            $store.state.hospitalCodes[citizen.接種地點] || ""
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
export default {
  name: "Paper",
  data: () => ({
    dayjs,
  }),
  computed: {
    type() {
      console.log(this.citizen.接種疫苗);
      return this.citizen.接種疫苗?.replace("_Kid", "").replace("_Baby", "");
    },
    citizen() {
      return this.$store.state.printer.citizen;
    },
    birth() {
      let 生日 = (this.citizen.出生年月日 * 1 + 19110000).toString();
      let 年 = 生日.substring(0, 4);
      let 月 = 生日.substring(4, 6);
      let 日 = 生日.substring(6);
      return { 年, 月, 日 };
    },
    age() {
      return dayjs().diff(
        dayjs(parseInt(this.citizen.出生年月日) + 19110000 + "").format(
          "YYYY-MM-DD"
        ),
        "year"
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.page {
  width: 210mm; /*隨著紙張大小異動*/
  height: 296mm; /*隨著紙張大小異動*/
  padding: 20mm;
  margin: 10mm auto;

  // background-image: url("/t.png") !important;
  // background-size: 210mm 297mm !important;
  // background-repeat: no-repeat !important;
  // background-position: center center !important;
}

@page {
  size: A4;
  margin: 0;
}
@media print {
  .page {
    font-size: 18px;
    margin: 0;
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    page-break-after: always;
    div {
      position: relative;
      width: 100%;
    }

    span {
      position: absolute;
    }
    .CoV_Novavax {
      .t0 {
        top: -12mm;
        left: -10mm;
        .t02 {
          left: 17mm;
        }
      }
      .t1 {
        padding-top: 25mm;
        top: 119mm;
        .t11 {
          left: 37mm;
        }
        .t12 {
          left: 139mm;
        }
      }
      .t2 {
        top: 130.5mm;
        .t21 {
          left: 31mm;
        }
        .t22 {
          left: 56mm;
        }
        .t23 {
          left: 77mm;
        }
        .t24 {
          left: 139mm;
        }
      }
      .t3 {
        top: 141.5mm;

        .t31 {
          left: 18mm;
        }
        .t32 {
          left: 50mm;
        }
        .t33 {
          left: 95mm;
          line-height: 20px;
          width: 80mm;
        }
      }
      .t4 {
        margin-top: -4mm;
        top: 214mm;
        .t41 {
          left: 15mm;
        }
        .t42 {
          left: 39mm;
        }
        .t43 {
          left: 58mm;
        }
      }
      .t5 {
        top: 224.5mm;
        .t51 {
          left: 45mm;
        }
      }
    }
    .CoV_bModerna {
      .t0 {
        top: -12mm;
        left: -10mm;
        .t02 {
          left: 17mm;
        }
      }
      .t1 {
        padding-top: 25mm;
        top: 119mm;
        .t11 {
          left: 37mm;
        }
        .t12 {
          left: 139mm;
        }
      }
      .t2 {
        top: 130.5mm;
        .t21 {
          left: 31mm;
        }
        .t22 {
          left: 56mm;
        }
        .t23 {
          left: 77mm;
        }
        .t24 {
          left: 139mm;
        }
      }
      .t3 {
        top: 141.5mm;

        .t31 {
          left: 18mm;
        }
        .t32 {
          left: 50mm;
        }
        .t33 {
          left: 95mm;
          line-height: 20px;
          width: 80mm;
        }
      }
      .t4 {
        margin-top: -4mm;
        top: 214mm;
        .t41 {
          left: 15mm;
        }
        .t42 {
          left: 39mm;
        }
        .t43 {
          left: 58mm;
        }
      }
      .t5 {
        top: 224.5mm;
        .t51 {
          left: 45mm;
        }
      }
    }
    .CoV_Medigen {
      .t0 {
        top: -12mm;
        left: -10mm;
        .t02 {
          left: 17mm;
        }
      }
      .t1 {
        padding-top: 10mm;
        top: 119mm;
        .t11 {
          left: 37mm;
        }
        .t12 {
          left: 139mm;
        }
      }
      .t2 {
        top: 130.5mm;
        .t21 {
          left: 31mm;
        }
        .t22 {
          left: 56mm;
        }
        .t23 {
          left: 77mm;
        }
        .t24 {
          left: 139mm;
        }
      }
      .t3 {
        top: 141.5mm;

        .t31 {
          left: 18mm;
        }
        .t32 {
          left: 50mm;
        }
        .t33 {
          left: 95mm;
          line-height: 20px;
          width: 80mm;
        }
      }
      .t4 {
        top: 214mm;
        .t41 {
          left: 15mm;
        }
        .t42 {
          left: 39mm;
        }
        .t43 {
          left: 58mm;
        }
      }
      .t5 {
        top: 224.5mm;
        .t51 {
          left: 45mm;
        }
      }
    }
    .CoV_AZ {
      .t0 {
        top: -16mm;
        left: -10mm;
        .t02 {
          left: 17mm;
        }
      }
      .t1 {
        padding-top: 10mm;
        top: 119mm;
        .t11 {
          left: 37mm;
        }
        .t12 {
          left: 139mm;
        }
      }
      .t2 {
        top: 130.5mm;
        .t21 {
          left: 31mm;
        }
        .t22 {
          left: 56mm;
        }
        .t23 {
          left: 77mm;
        }
        .t24 {
          left: 139mm;
        }
      }
      .t3 {
        top: 141.5mm;

        .t31 {
          left: 18mm;
        }
        .t32 {
          left: 50mm;
        }
        .t33 {
          left: 95mm;
          line-height: 20px;
          width: 80mm;
        }
      }
      .t4 {
        top: 214mm;
        .t41 {
          left: 15mm;
        }
        .t42 {
          left: 39mm;
        }
        .t43 {
          left: 58mm;
        }
      }
      .t5 {
        top: 224.5mm;
        .t51 {
          left: 45mm;
        }
      }
    }
    .CoV_Moderna {
      .t0 {
        top: -16mm;
        left: -10mm;
        .t02 {
          left: 17mm;
        }
      }
      .t1 {
        top: 119mm;
        .t11 {
          left: 37mm;
        }
        .t12 {
          left: 139mm;
        }
      }
      .t2 {
        top: 130.5mm;
        .t21 {
          left: 31mm;
        }
        .t22 {
          left: 56mm;
        }
        .t23 {
          left: 77mm;
        }
        .t24 {
          left: 139mm;
        }
      }
      .t3 {
        top: 141.5mm;

        .t31 {
          left: 18mm;
        }
        .t32 {
          left: 50mm;
        }
        .t33 {
          left: 95mm;
          line-height: 20px;
          width: 80mm;
        }
      }
      .t4 {
        top: 214mm;
        .t41 {
          left: 15mm;
        }
        .t42 {
          left: 39mm;
        }
        .t43 {
          left: 58mm;
        }
      }
      .t5 {
        top: 224.5mm;
        .t51 {
          left: 45mm;
        }
      }

      .tk {
        top: 178mm;
        > span {
          font-weight: bolder;
        }
        .tk1 {
          left: -8mm;
          span {
            position: relative;
            display: inline-block;
          }
        }
        .tk2 {
          left: 113mm;
          span {
            position: relative;
            display: inline-block;
          }
        }
        .tk3 {
          top: 12mm;
          left: 60mm;

          span {
            border-bottom: 1.5px solid #000;
            display: inline-block;
            width: 85mm;
            height: 30px;
            font-weight: normal;
          }
        }
        .underline {
          border-bottom: 1.5px solid #000;
          display: inline-block;
          width: 30mm;
          height: 30px;
          font-weight: normal;
        }
        .unit {
          border-bottom: 1.5px solid #000;
          height: 30px;
          font-weight: normal;
          padding-left: 15mm;
        }
      }
    }
    .CoV_BioNTech {
      margin-left: 3mm;
      .t0 {
        top: -10mm;
        left: -10mm;
        .t02 {
          left: 17mm;
        }
      }
      .t1 {
        padding-top: 9mm;
        top: 119mm;
        .t11 {
          left: 37mm;
        }
        .t12 {
          left: 139mm;
        }
      }
      .t2 {
        top: 130.5mm;
        .t21 {
          left: 31mm;
        }
        .t22 {
          left: 56mm;
        }
        .t23 {
          left: 77mm;
        }
        .t24 {
          left: 139mm;
        }
      }
      .t3 {
        top: 141.5mm;

        .t31 {
          left: 18mm;
        }
        .t32 {
          left: 50mm;
        }
        .t33 {
          left: 95mm;
          line-height: 20px;
          width: 80mm;
        }
      }
      .t4 {
        margin-top: -2mm;
        top: 214mm;
        .t41 {
          left: 15mm;
        }
        .t42 {
          left: 39mm;
        }
        .t43 {
          left: 58mm;
        }
      }
      .t5 {
        top: 224.5mm;
        .t51 {
          left: 45mm;
        }
      }

      .tk {
        margin-top: 1mm;
        top: 178mm;
        > span {
          font-weight: bolder;
        }
        .tk1 {
          left: -8mm;
          span {
            position: relative;
            display: inline-block;
          }
        }
        .tk2 {
          left: 113mm;
          span {
            position: relative;
            display: inline-block;
          }
        }
        .tk3 {
          top: 12mm;
          left: 60mm;

          span {
            border-bottom: 1.5px solid #000;
            display: inline-block;
            width: 85mm;
            height: 30px;
            font-weight: normal;
          }
        }
        .underline {
          border-bottom: 1.5px solid #000;
          display: inline-block;
          width: 30mm;
          height: 30px;
          font-weight: normal;
        }
        .unit {
          border-bottom: 1.5px solid #000;
          height: 30px;
          font-weight: normal;
          padding-left: 15mm;
        }
      }
    }
  }
}
</style>
