<template>
  <div>
    <div class="page">
      <div class="t1">
        <span class="l1">{{ citizen.姓名 }}</span>
        <span class="l2">{{ citizen.身分證字號 }}</span>
      </div>
      <div class="t2">
        <span class="l3">{{ birth.年 }}</span>
        <span class="l4">{{ birth.月 }}</span>
        <span class="l5">{{ birth.日 }}</span>
        <span class="l6">{{ citizen.電話 }}</span>
      </div>
      <div class="t3">
        <span class="l7">{{ citizen.縣市 }}</span>
        <span class="l8">{{ citizen.行政區域 || citizen.行政區 || "" }}</span>
        <span class="l9">{{ citizen.地址 || "" }}</span>
      </div>
      <!-- <div class="t4">
        <span class="l10">{{ citizen.身分證字號 }}</span>
      </div> -->
      <div class="t5">
        <span class="l11">{{ dayjs().format("YYYY") }}</span>
        <span class="l12">{{ dayjs().format("MM") }}</span>
        <span class="l13">{{ dayjs().format("DD") }}</span>
      </div>
      <div class="t6">
        <span class="l14">{{
          $store.state.hospitalCodes[citizen.接種地點] || ""
        }}</span>
      </div>
      <div class="t7">
        <span class="l15">#{{ citizen.報到號碼 }}</span>
        <span class="l16">{{
          dayjs(citizen.報到時間).format("MM/DD HH:mm")
        }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
export default {
  name: "PaperNovavax",
  data: () => ({
    test: "sss",
    dayjs,
  }),
  computed: {
    citizen() {
      return this.$store.state.printer.citizenNovavax;
    },
    birth() {
      let 生日 = (this.citizen.出生年月日 * 1 + 19110000).toString();
      let 年 = 生日.substring(0, 4);
      let 月 = 生日.substring(4, 6);
      let 日 = 生日.substring(6);
      return { 年, 月, 日 };
    },
    age() {
      return dayjs().diff(
        dayjs(parseInt(this.citizen.出生年月日) + 19110000 + "").format(
          "YYYY-MM-DD"
        ),
        "year"
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.page {
  width: 210mm; /*隨著紙張大小異動*/
  height: 296mm; /*隨著紙張大小異動*/
  padding: 20mm;
  margin: 10mm auto;
  border: 1px #d3d3d3 solid;
  background: white;

  // height: 297mm;
  // background-image: url("/t.png") !important;
  // background-size: 210mm 297mm !important;
  // background-repeat: no-repeat !important;
  // background-position: center center !important;
}

@page {
  size: A4;
  margin: 0;
}
@media print {
  .page {
    font-size: 18px;
    margin: 0;
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    page-break-after: always;
    div {
      position: relative;
      width: 100%;
    }

    span {
      position: absolute;
    }
    .t1 {
      top: 144mm;
      .l1 {
        left: 40mm;
      }
      .l2 {
        left: 140mm;
      }
    }
    .t2 {
      top: 156mm;
      .l3 {
        left: 33mm;
      }
      .l4 {
        left: 58mm;
      }
      .l5 {
        left: 77mm;
      }
      .l6 {
        left: 132mm;
      }
    }
    .t3 {
      margin-top: 166.5mm;
      .l7 {
        left: 18mm;
      }
      .l8 {
        left: 52mm;
      }
      .l9 {
        left: 96mm;
        line-height: 20px;
      }
    }
    .t5 {
      margin-top: 234.5mm;
      .l11 {
        left: 17mm;
      }
      .l12 {
        left: 38mm;
      }
      .l13 {
        left: 58mm;
      }
    }
    .t6 {
      margin-top: 246mm;
      .l14 {
        left: 44mm;
      }
    }
    .t7 {
      margin-top: -259mm;
      .l15 {
        left: -9mm;
        font-size: 20px;
      }
      .l16 {
        left: 10mm;
        font-size: 20px;
      }
    }
    .t8 {
      top: 134mm;
      .underline {
        border-bottom: 1.5px solid #000;
        display: inline-block;
        width: 35mm;
        height: 26px;
        font-weight: normal;
      }
      .l17 {
        top: -8mm;
        left: -5mm;
        font-size: 19px;
        font-weight: bolder;
      }
      .l18 {
        left: -5mm;
        font-size: 19px;
        font-weight: bolder;
      }
      .l19 {
        top: -8mm;
        left: 50mm;
        font-size: 19px;
        font-weight: bolder;
      }
      .l20 {
        top: -7.5mm;
        left: 75mm;
        font-size: 17px;
        font-weight: bolder;
        width: 100mm;
        input[type="checkbox"] {
          transform: scale(1.2);
          padding: 10px;
        }
      }
      .l21 {
        top: 0mm;
        left: 50mm;
        font-size: 19px;
        font-weight: bolder;
      }
    }
  }
}
</style>