<template>
  <v-dialog v-model="propModel" max-width="300px" persistent>
    <v-card>
      <v-card-title>移除人員</v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-select
              v-model="梯次"
              :items="梯次List"
              label="梯次"
              dense
              hide-details
              outlined
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              v-model="身分證字號"
              label="身分證字號"
              outlined
              dense
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" text @click="reset"> 取消 </v-btn>
        <v-btn color="blue darken-1" text @click="dataDelete">移除</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import _ from "lodash";
export default {
  name: "ListDelete",
  props: ["value", "params"],
  data: () => ({
    梯次: "",
    身分證字號: "",
  }),
  computed: {
    梯次List() {
      let list = _.cloneDeep(this.params.梯次List);
      list.shift();
      list.pop();
      return list;
    },
    propModel: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    async dataDelete() {
      await this.$axios.delete(`/citizen/${this.梯次}/${this.身分證字號}`);
      this.reset();
    },
    reset() {
      this.file = null;
      this.身分證字號 = "";
      this.propModel = false;
      this.$emit("reload");
    },
  },
  watch: {
    propModel(value) {
      if (value) {
        this.梯次 = this.params.梯次;
      }
    },
  },
};
</script>

<style>
</style>