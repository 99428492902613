<template>
  <v-dialog v-model="propModel" max-width="300px" persistent>
    <v-card>
      <v-card-title>匯入名單</v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-combobox
              v-model="梯次"
              :items="梯次List"
              label="梯次"
              dense
              hide-details
              outlined
            ></v-combobox>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-file-input
              v-model="file"
              prepend-icon=""
              accept=".csv"
              label="檔案"
              outlined
              dense
              hide-details
            ></v-file-input>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" text @click="reset"> 取消 </v-btn>
        <v-btn color="blue darken-1" text @click="dataImport">匯入</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import _ from "lodash";
export default {
  name: "ListImport",
  props: ["value", "params"],
  data: () => ({
    梯次: "",
    file: null,
  }),
  computed: {
    梯次List() {
      let list = _.cloneDeep(this.params.梯次List);
      list.shift();
      list.pop();
      return list;
    },
    propModel: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    async dataImport() {
      if (this.file) {
        let formData = new FormData();
        formData.append("file", this.file, this.file.name);
        formData.append("梯次", this.梯次);
        let result = (await this.$axios.post("/importList", formData)).data;
        alert(`檔案內筆數: ${result.total}\n成功匯入筆數: ${result.insert}`);
        this.reset();
      }
    },
    reset() {
      this.file = null;
      this.梯次 = "";
      this.propModel = false;
      this.$emit("reload");
    },
  },
};
</script>

<style>
</style>