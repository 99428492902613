<template>
  <v-container fluid>
    <v-row>
      <v-col :cols="5" class="text-h6">
        台南市政府疫苗施打通報系統
        <v-btn class="text-caption" plain text @click="logout">[登出]</v-btn>
      </v-col>
      <v-col :cols="7" align="right">
        <v-menu
          offset-y
          open-on-hover
          v-if="
            permit.btnListImport ||
              permit.btnNiisImport ||
              permit.btnPopulationImport ||
              permit.btnHospitalImport ||
              permit.btnListDelete
          "
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="mr-4" color="secondary" v-bind="attrs" v-on="on">
              梯次編輯
            </v-btn>
          </template>
          <v-list>
            <v-list-item link :disabled="!permit.btnNiisImport">
              <v-list-item-title @click="niisImportDialog = true"
                >匯入 NIIS</v-list-item-title
              >
            </v-list-item>
            <v-list-item link :disabled="!permit.btnListImport">
              <v-list-item-title @click="listImportDialog = true"
                >匯入梯次</v-list-item-title
              >
            </v-list-item>
            <v-list-item link :disabled="!permit.btnHospitalImport">
              <v-list-item-title @click="hospitalImportDialog = true"
                >匯入醫事單位</v-list-item-title
              >
            </v-list-item>
            <v-list-item link :disabled="!permit.btnPopulationImport">
              <v-list-item-title @click="populationImportDialog = true"
                >匯入戶籍</v-list-item-title
              >
            </v-list-item>
            <v-list-item link :disabled="!permit.btnListDelete">
              <v-list-item-title @click="listDeleteDialog = true"
                >移除人員</v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>
        <v-menu
          offset-y
          open-on-hover
          v-if="permit.btnCancelReservation || permit.btnOnSiteAssignDialog"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="mr-4" color="error" v-bind="attrs" v-on="on">
              疫苗配發
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              link
              @click="onSiteAssignDialog = true"
              :disabled="!permit.btnOnSiteAssignDialog"
            >
              <v-list-item-title>現場施打</v-list-item-title>
            </v-list-item>
            <v-list-item
              link
              @click="cancelReservationDialog = true"
              :disabled="!permit.btnCancelReservation"
            >
              <v-list-item-title>取消配發</v-list-item-title>
            </v-list-item>

            <!-- <v-list-item link @click="reAssign" :disabled="!permit.btnAssign">
              <v-list-item-title>批次配發</v-list-item-title>
            </v-list-item> -->
          </v-list>
        </v-menu>
        <v-menu
          offset-y
          open-on-hover
          v-if="permit.btnSetHospital || permit.btnAddCitizen"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="mr-4" color="success" v-bind="attrs" v-on="on">
              功能設定
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              link
              @click="hospitalDialog = true"
              :disabled="!permit.btnSetHospital"
            >
              <v-list-item-title>設定醫事地點</v-list-item-title>
            </v-list-item>
            <v-list-item
              link
              @click="citizenDialog = true"
              :disabled="!permit.btnAddCitizen"
            >
              <v-list-item-title>加入施打名冊</v-list-item-title>
            </v-list-item>
            <v-list-item
              link
              @click="systemLog"
              :disabled="!permit.btnSystemLog"
            >
              <v-list-item-title>系統事件記錄</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-menu
          offset-y
          open-on-hover
          v-if="
            permit.btnNIIS || permit.btnPrintContact || permit.btnPrintNotice
          "
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="mr-4" color="warning" v-bind="attrs" v-on="on">
              報表匯出
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              link
              @click="reserveReportDialog = true"
              :disabled="!permit.btnReserveReport"
            >
              <v-list-item-title>每日數量</v-list-item-title>
            </v-list-item>
            <v-list-item
              link
              @click="printAgreementDialog = true"
              :disabled="!permit.btnPrintAgreement"
            >
              <v-list-item-title>列印同意書</v-list-item-title>
            </v-list-item>
            <v-list-item
              link
              @click="headerDialog = true"
              :disabled="!permit.btnPrintContact"
            >
              <v-list-item-title>匯出列表</v-list-item-title>
            </v-list-item>
            <v-list-item
              link
              @click="print(`通知單`)"
              :disabled="!permit.btnPrintNotice"
            >
              <v-list-item-title>匯出通知單</v-list-item-title>
            </v-list-item>
            <v-list-item link @click="exportNIIS" :disabled="!permit.btnNIIS">
              <v-list-item-title>匯出 NIIS</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn color="info" @click="getCitizens">重整名單</v-btn>
      </v-col>
    </v-row>
    <v-row v-if="isManager">
      <v-col :cols="3">
        <v-select
          v-model="縣市"
          :items="縣市List"
          label="縣市"
          dense
          hide-details
          outlined
          disabled
        ></v-select>
      </v-col>
      <v-col :cols="3">
        <v-select
          v-model="梯次"
          :items="梯次List"
          label="梯次"
          dense
          hide-details
          outlined
        ></v-select>
      </v-col>
      <v-col :cols="3">
        <v-select
          v-model="行政區"
          :items="行政區List"
          label="行政區/工作人員分類"
          dense
          hide-details
          outlined
          :disabled="!permit.changeDistrict"
        ></v-select>
      </v-col>
      <v-col :cols="3">
        <v-select
          v-model="村里"
          :items="村里List"
          label="村里/單位"
          dense
          hide-details
          outlined
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <template v-if="user.角色 == '區公所'">
        <v-col :cols="2">
          <v-select
            v-model="村里"
            :items="村里List"
            label="村里/單位"
            dense
            hide-details
            outlined
          ></v-select>
        </v-col>
        <v-col :cols="2">
          <v-combobox
            dense
            outlined
            v-model="hospital"
            :items="hospitalList"
            :return-object="false"
            hide-details
            label="接種地點"
            :disabled="!!this.user.醫事單位 && this.user.角色 == '醫事單位'"
          ></v-combobox>
        </v-col>
        <v-col :cols="2">
          <v-menu
            :close-on-content-click="true"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="reserveDate"
                label="接種日期"
                outlined
                readonly
                clearable
                v-bind="attrs"
                v-on="on"
                dense
                hide-details
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="reserveDate"
              no-title
              scrollable
              locale="zh-tw"
            >
            </v-date-picker>
          </v-menu>
        </v-col>
      </template>
      <template v-else>
        <v-col :cols="3">
          <v-combobox
            dense
            outlined
            v-model="hospital"
            :items="hospitalList"
            :return-object="false"
            hide-details
            label="接種地點"
            :disabled="!!this.user.醫事單位 && this.user.角色 == '醫事單位'"
          ></v-combobox>
        </v-col>
        <v-col :cols="3">
          <v-menu
            :close-on-content-click="true"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="reserveDate"
                label="接種日期"
                outlined
                readonly
                clearable
                v-bind="attrs"
                v-on="on"
                dense
                hide-details
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="reserveDate"
              no-title
              scrollable
              locale="zh-tw"
            >
            </v-date-picker>
          </v-menu>
        </v-col>
      </template>

      <v-col :cols="2">
        <v-text-field
          v-model.lazy="keyword"
          id="keyword"
          label="姓名、身分證、報到號碼搜尋"
          placeholder="姓名、身分證、報到號碼"
          outlined
          dense
          hide-details
          clearable
          :append-icon="'mdi-barcode-scan'"
          @click:append="scanHandler(keyword)"
        ></v-text-field>
      </v-col>
      <v-col :cols="2">
        <v-select
          v-model="condition"
          :items="conditionList"
          label="顯示條件"
          dense
          hide-details
          outlined
        ></v-select>
      </v-col>
      <v-col :cols="2" class="report">
        <span>已接種</span> {{ report.已接種 }} 人
        <br />
        <span>已報到</span> {{ report.已報到 }} 人
        <br />
        <span>全部</span> {{ report.全部 }} 人
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-divider></v-divider>
      </v-col>
    </v-row>
    <div class="list-table">
      <v-data-table
        ref="table"
        :sort-by.sync="listSortBy"
        :sort-desc.sync="listSortDesc"
        :page.sync="currentPage"
        :headers="headers"
        :items="filterCitizens"
        no-data-text="沒有資料"
        :fixed-header="true"
        multi-sort
        :height="`calc(100vh - ${tableOffset + 74}px)`"
        :mobile-breakpoint="0"
        :items_per_page="[50, 100, 300, -1]"
        :footer-props="{
          itemsPerPageText: '每頁幾筆',
          itemsPerPageOptions: [50, 100, 300],
        }"
      >
        <template v-slot:item.序號="{ index }"> {{ index + 1 }} </template>
        <template v-slot:item.行政區="{ item }"
          ><p class="ellipsis" :title="item.行政區">
            {{ item.行政區 }}
          </p></template
        >
        <template v-slot:item.村里="{ item }"
          ><p class="ellipsis" :title="item.村里">{{ item.村里 }}</p></template
        >
        <template v-slot:item.接種地點="{ item }">
          <p class="ellipsis" :title="item.接種地點">{{ item.接種地點 }}</p>
        </template>
        <template v-slot:item.姓名="{ item }">
          <v-edit-dialog
            v-if="permit.inlineEdit"
            :return-value.sync="item.姓名"
            @save="changeBox(item, '姓名')"
          >
            <span class="text-decoration-underline">{{ item.姓名 }}</span>
            <template v-slot:input>
              <v-text-field
                class="mb-2"
                v-model="item.姓名"
                single-line
                autofocus
                hint="請填入姓名"
              ></v-text-field>
            </template>
          </v-edit-dialog>
          <template v-else>{{ item.姓名 }}</template>
        </template>
        <template v-slot:item.身分證字號="{ item }">
          <v-edit-dialog
            v-if="permit.inlineEdit"
            :return-value.sync="item.身分證字號"
            @save="changeBox(item, '身分證字號')"
          >
            <span class="text-decoration-underline">{{ item.身分證字號 }}</span>
            <template v-slot:input>
              <v-text-field
                class="mb-2"
                v-model="item.身分證字號"
                single-line
                autofocus
                hint="請填入身分證字號"
              ></v-text-field>
            </template>
          </v-edit-dialog>
          <template v-else>{{ item.身分證字號 }}</template>
        </template>
        <template v-slot:item.出生年月日="{ item }">
          <v-edit-dialog
            v-if="permit.inlineEdit"
            :return-value.sync="item.出生年月日"
            @save="changeBox(item, '出生年月日')"
          >
            <span class="text-decoration-underline">{{ item.出生年月日 }}</span>

            <template v-slot:input>
              <v-text-field
                class="mb-2"
                v-model="item.出生年月日"
                single-line
                hint="請填入生日(民國年)"
                autofocus
              ></v-text-field>
            </template>
          </v-edit-dialog>
          <template v-else>{{ item.出生年月日 }}</template>
        </template>
        <template v-slot:item.電話="{ item }">
          <v-edit-dialog
            v-if="permit.inlineEdit"
            :return-value.sync="item.電話"
            @save="changeBox(item, '電話')"
          >
            <span class="text-decoration-underline">{{ item.電話 }}</span>
            <template v-slot:input>
              <v-text-field
                class="mb-2"
                v-model="item.電話"
                single-line
                autofocus
                hint="請填入電話"
              ></v-text-field>
            </template>
          </v-edit-dialog>
          <template v-else>{{ item.電話 }}</template>
        </template>
        <template v-slot:item.有意願="{ item }">
          <v-simple-checkbox
            @input="changeBox(item, '有意願')"
            :off-icon="
              item.有意願 === null
                ? 'mdi-crop-free'
                : 'mdi-checkbox-blank-outline'
            "
            v-model="item.有意願"
            :ripple="false"
          ></v-simple-checkbox>
        </template>
        <template v-slot:item.需接送="{ item }">
          <v-simple-checkbox
            @input="changeBox(item, '需接送')"
            v-model="item.需接送"
            :ripple="false"
          ></v-simple-checkbox>
        </template>
        <template v-slot:item.需到府="{ item }">
          <v-simple-checkbox
            @input="changeBox(item, '需到府')"
            v-model="item.需到府"
            :ripple="false"
          ></v-simple-checkbox>
        </template>
        <template v-slot:item.已通知="{ item }">
          <v-simple-checkbox
            @input="changeBox(item, '已通知')"
            :off-icon="
              item.已通知 === null
                ? 'mdi-crop-free'
                : 'mdi-checkbox-blank-outline'
            "
            v-model="item.已通知"
            :ripple="false"
          ></v-simple-checkbox>
        </template>
        <template v-slot:item.已報到="{ item }">
          <v-simple-checkbox
            @input="changeBox(item, '已報到')"
            :off-icon="
              item.已報到 === null
                ? 'mdi-crop-free'
                : 'mdi-checkbox-blank-outline'
            "
            v-model="item.已報到"
            :ripple="false"
          ></v-simple-checkbox>
        </template>
        <template v-slot:item.報到時間="{ item }">
          <v-edit-dialog
            v-if="permit.inlineEdit"
            :return-value.sync="item.報到時間"
            @save="changeBox(item, '報到時間')"
            @open="item.報到時間 = timeFormat(item.報到時間)"
            lazy
          >
            <span class="text-decoration-underline">{{
              timeFormat(item.報到時間)
            }}</span>
            <template v-slot:input>
              <v-text-field
                class="mb-2"
                v-model="item.報到時間"
                single-line
                autofocus
              ></v-text-field>
            </template>
          </v-edit-dialog>
          <template v-else>{{ timeFormat(item.報到時間) }}</template>
        </template>
        <template v-slot:item.醫生不同意="{ item }">
          <v-simple-checkbox
            @input="changeBox(item, '醫生不同意')"
            v-model="item.醫生不同意"
            :ripple="false"
          ></v-simple-checkbox>
        </template>
        <template v-slot:item.報到號碼="{ item }">
          {{ item.報到號碼 ? `#${item.報到號碼}` : "-" }}
        </template>
        <template v-slot:item.接種劑別="{ item }">
          <span v-if="item.接種劑別 == '1'">{{ item.接種劑別 }}</span>
          <span v-else class="error--text font-weight-bold">{{
            item.接種劑別
          }}</span>
        </template>
        <template v-slot:item.已接種疫苗="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div class="text-decoration-underline" v-bind="attrs" v-on="on">
                {{ getLastVaccine(item.已接種疫苗) }}
              </div>
            </template>
            <div
              v-for="(date, key) of item.已接種疫苗"
              class="font-weight-bold"
            >
              {{ key }} ({{ dateFormat(date) }})
            </div>
          </v-tooltip>
        </template>
      </v-data-table>
    </div>

    <Hospital
      :isManager="isManager"
      :isAdmin="isAdmin"
      v-model="hospitalDialog"
      :params="{ 縣市, 行政區, 行政區List, 縣市List }"
    ></Hospital>
    <ReserveReport
      :isManager="isManager"
      v-model="reserveReportDialog"
      :params="{ 縣市, 行政區, 行政區List, 縣市List }"
    ></ReserveReport>
    <Citizen
      v-model="citizenDialog"
      @reload="getCitizens"
      :params="{ 縣市, 行政區, 梯次, 村里, 行政區List, 梯次List, 縣市List }"
    ></Citizen>
    <OnSiteAssign
      v-model="onSiteAssignDialog"
      :params="{ 縣市, 行政區, hospital }"
      @reload="getCitizens"
    ></OnSiteAssign>
    <CancelReservation
      v-model="cancelReservationDialog"
      @reload="getCitizens"
    ></CancelReservation>
    <PrintList
      v-model="headerDialog"
      :allHeaders="allHeaders"
      :defaultHeaders="defaultHeaders"
      @print="print"
    ></PrintList>
    <ListImport
      v-model="listImportDialog"
      :params="{ 梯次List }"
      @reload="
        getCitizens();
        getTurns();
      "
    ></ListImport>
    <NiisImport v-model="niisImportDialog"></NiisImport>
    <PopulationImport v-model="populationImportDialog"></PopulationImport>
    <HospitalImport v-model="hospitalImportDialog"></HospitalImport>
    <ListDelete
      v-model="listDeleteDialog"
      :params="{ 梯次, 梯次List }"
      @reload="getCitizens"
    ></ListDelete>
    <PrintAgreement v-model="printAgreementDialog"></PrintAgreement>
    <v-overlay :value="spin">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import _ from "lodash";
import { mapState } from "vuex";
import dayjs from "dayjs";
import downloadCsv from "download-csv";
import barcodeReader from "@wrurik/barcode-scanner";
// import print from "@/libs/print.js";
const getPrint = () => import("@/libs/print.js");
import Hospital from "@/components/Hospital.vue";
import Citizen from "@/components/Citizen.vue";
import CancelReservation from "@/components/CancelReservation.vue";
import OnSiteAssign from "@/components/OnSiteAssign.vue";
import PrintList from "@/components/PrintList.vue";
import ReserveReport from "@/components/ReserveReport.vue";
import ListImport from "@/components/ListImport.vue";
import NiisImport from "@/components/NiisImport.vue";
import PopulationImport from "@/components/PopulationImport.vue";
import HospitalImport from "@/components/HospitalImport.vue";
import ListDelete from "@/components/ListDelete.vue";
import PrintAgreement from "@/components/PrintAgreement.vue";

import {
  梯次,
  行政區List,
  allHeaders,
  conditionList,
  conditionFunction,
  role,
} from "@/config.js";
export default {
  name: "Dashboard",
  components: {
    Hospital,
    Citizen,
    CancelReservation,
    OnSiteAssign,
    PrintList,
    ReserveReport,
    ListImport,
    ListDelete,
    PrintAgreement,
    NiisImport,
    PopulationImport,
    HospitalImport,
  },
  data: () => ({
    梯次,
    縣市: "台南市",
    行政區: "",
    村里: "",
    hospital: "",
    梯次List: [],
    縣市List: ["台南市"],
    行政區List,
    村里List: [],
    conditionList,
    hospitals: [],
    citizens: [],
    citizen: null,
    headers: [],
    allHeaders,
    exportHeaders: [],
    hospitalDialog: false,
    citizenDialog: false,
    headerDialog: false,
    cancelReservationDialog: false,
    onSiteAssignDialog: false,
    reserveReportDialog: false,
    listImportDialog: false,
    niisImportDialog: false,
    populationImportDialog: false,
    hospitalImportDialog: false,
    listDeleteDialog: false,
    printAgreementDialog: false,
    assignResult: {},
    currentPage: 1,
    keyword: "",
    reserveDate: "",
    tableOffset: 0,
    permit: {},
    condition: null,
    spin: false,
    printModule: null,
    report: {
      全部: 0,
      已報到: 0,
      已接種: 0,
    },
    listSortBy: [],
    listSortDesc: [],
  }),
  created() {
    if (!this.$store.state.login) this.$router.push("/login");
    this.$store.commit("scan", "");

    this.梯次 = this.selectedState.梯次 || this.user.梯次 || "";
    this.縣市 = this.selectedState.縣市 || this.user.縣市 || "";
    this.行政區 = this.selectedState.行政區 || this.user.行政區 || "";
    this.村里 = this.selectedState.村里 || this.user.村里 || "";
    this.hospital = this.selectedState.hospital || this.user.醫事單位 || "";
    this.keyword = this.selectedState.keyword || "";
    this.condition = this.selectedState.condition || "";
    this.reserveDate =
      this.selectedState.reserveDate ||
      (this.isManager ? "" : dayjs().format("YYYY-MM-DD"));
    this.headers = role[this.user.角色].headers;
    this.permit = role[this.user.角色].permit;
    this.getHospitals();
    this.getTurns();

    barcodeReader.onScan(this.scanHandler, { scanDuration: 500 });
  },
  mounted() {
    this.tableOffset = this.$refs.table.$el.offsetTop;
  },
  computed: {
    ...mapState(["user", "selectedState"]),
    dialogOpen() {
      return (
        this.hospitalDialog ||
        this.citizenDialog ||
        this.headerDialog ||
        this.cancelReservationDialog ||
        this.onSiteAssignDialog ||
        this.reserveReportDialog ||
        this.listImportDialog ||
        this.niisImportDialog ||
        this.populationImportDialog ||
        this.hospitalImportDialog ||
        this.listDeleteDialog ||
        this.printAgreementDialog
      );
    },
    isManager() {
      return ["管理者", "衛生局", "衛生所"].includes(this.user.角色);
    },
    isAdmin() {
      return ["管理者"].includes(this.user.角色);
    },
    defaultHeaders() {
      return this.headers.map((header) => header.text);
    },
    filterCitizens() {
      let list = this.citizens;

      if (this.村里) {
        list = list.filter((citizen) => citizen.村里 == this.村里);
      }

      if (this.reserveDate) {
        list = list.filter(
          (citizen) =>
            citizen.接種時間 &&
            dayjs(citizen.接種時間).format("YYYY-MM-DD") == this.reserveDate
        );
      }

      this.report.全部 = list.length;
      this.report.已報到 = list.filter((citizen) => citizen.已報到).length;
      this.report.已接種 = list.filter(
        (citizen) => citizen.已報到 && !citizen.醫生不同意
      ).length;

      if (this.keyword) {
        if (this.keyword.includes("#")) {
          list = list.filter((citizen) => {
            return (
              citizen.報到號碼 &&
              citizen.報到號碼 == parseInt(this.keyword.replace("#", ""))
            );
          });
        } else {
          list = list.filter(
            (citizen) =>
              citizen.姓名.toLowerCase().includes(this.keyword.toLowerCase()) ||
              citizen.身分證字號
                .toLowerCase()
                .includes(this.keyword.toLowerCase())
          );
        }
      }

      if (this.condition) {
        list = conditionFunction[this.condition](list);
      }

      return list;
    },
    hospitalList() {
      let list = this.hospitals;
      list = list.filter((hospital) => hospital.名稱);

      if (this.縣市) {
        list = list.filter((hospital) => this.縣市 == hospital.縣市);
      }
      if (this.行政區) {
        list = list.filter((hospital) => this.行政區 == hospital.行政區);
      }
      list = list.map((hospital) => ({
        text: hospital.名稱,
        value: hospital.名稱,
      }));

      // if (this.hospital) list.unshift(this.hospital);

      list.unshift({ text: "全選", value: "" });
      return list;
    },
  },
  methods: {
    getLastVaccine(obj) {
      if (_.isEmpty(obj)) return "";

      let maxDate = Object.values(obj).reduce((a, b) => {
        return a > b ? a : b;
      });

      let maxDateKey = Object.keys(obj).find((key) => obj[key] === maxDate);
      return `${maxDateKey} (${this.dateFormat(maxDate)})`;
    },
    async systemLog() {
      window.open("/SystemLog", "_blank");
    },
    async getTurns() {
      let list = (await this.$axios.get("/citizen/turns")).data;
      list.unshift({ text: "全梯次", value: "" });
      this.梯次List = list;
    },
    scanHandler(value, e) {
      if (!value) return;
      this.$store.commit("scan", value);
      if (
        document.activeElement.id != "keyword" &&
        !this.dialogOpen &&
        /^[A-Z](1|2)\d{8}$/i.test(value)
      ) {
        this.keyword = value;
        this.$nextTick(async () => {
          let findPerson = this.filterCitizens.find(
            (citizen) => citizen.身分證字號 == value
          );

          if (findPerson) {
            if (findPerson.已報到) {
              this.$toast.error("此人已報到過！");
            } else {
              // if (!findPerson.地址) {
              //   this.$toast.warning(`請注意！此人沒有地址，可能不是台南戶籍！`);
              // }
              // if (findPerson.接種劑別 == "2") {
              //   this.$toast.warning(`請注意！此人預約第二劑！`);
              // }
              // if (!_.isEmpty(findPerson.已接種疫苗)) {
              //   this.$toast.warning(`請注意！此人接種過疫苗！`);
              // }

              findPerson.已報到 = true;
              await this.changeBox(findPerson, "已報到");
              this.$toast.success(
                `#${findPerson.報到號碼} ${findPerson.姓名} ${findPerson.出生年月日} 完成報到！`
              );
            }
          } else {
            findPerson = this.citizens.find(
              (citizen) => citizen.身分證字號 == value
            );
            if (findPerson) {
              this.$toast.error(
                "此人在報到名單內，但已被篩選過濾，請調整過濾條件後重新掃描！"
              );
            } else {
              let params = {
                身分證字號: value,
              };
              let listed = (await this.$axios.get("/citizen", { params })).data;
              if (
                listed &&
                !!Object.keys(listed.已接種疫苗).length &&
                已接種疫苗["CoV_AZ-1"] != null
              ) {
                this.$toast.error("此人已施打過疫苗！");
              } else if (listed && listed.已報到 && !listed.醫生不同意) {
                this.$toast.error("此人已施打過疫苗！");
              } else if (listed && listed.取消時間 && listed.預約時間) {
                this.$toast.error(
                  `此人在 ${dayjs(listed.預約時間).format(
                    "MM/DD HH:mm"
                  )} 預約「${listed.預約地點}」，但於 ${dayjs(
                    listed.取消時間
                  ).format("MM/DD HH:mm")} 取消了預約。`
                );
              } else if (listed && listed.取消時間) {
                this.$toast.error(
                  `此人在 ${dayjs(listed.取消時間).format(
                    "MM/DD HH:mm"
                  )} 取消了預約。`
                );
              } else if (listed) {
                this.$toast.error("此人不在報到名單內，需現場配發！");
              } else {
                this.$toast.error(
                  "此人不在造冊資格內，詢問主管是否現場人工登記!"
                );
              }
            }
          }
        });
      }
    },
    dateFormat(date) {
      return date ? dayjs(date).format("YYYY-MM-DD") : "";
    },
    timeFormat(time) {
      return time ? dayjs(time).format("YYYY-MM-DD HH:mm:ss") : "";
    },
    exportNIIS() {
      let fileName = `${dayjs().format("YYYY-MM-DD")} ${this.縣市}${
        this.行政區
      }${this.村里}${this.梯次} NIIS 匯入檔.csv`;
      let list = this.filterCitizens;
      list = list.filter((row) => row.已報到 && !row.醫生不同意);
      list = list.map((row) => ({
        身分證號: row.身分證字號,
        姓名: row.姓名,
        性別: row.身分證字號[1] == "1" ? "M" : "F",
        出生日期: row.出生年月日,
        同胎次序: 1,
        通訊地址: row.地址 || "",
        電話: row.電話 ? row.電話.toString().substring(0, 12) : "",
        父或母身分證號: "",
        接種機構: "",
        接種日期: (parseInt(dayjs().format("YYYYMMDD")) - 19110000)
          .toString()
          .padStart(7, "0"),
        疫苗種類: row.接種疫苗 ? row.接種疫苗.replace("_Kid", "") : "",
        疫苗劑別: row.接種劑別,
        疫苗批號: "",
        疫苗廠商: "",
        疫苗型別: "",
        曾經接種流感疫苗: "",
        身份別: row.類別,
      }));
      // let columns = {};
      downloadCsv(list, {}, fileName);
    },
    logout() {
      this.$store.commit("logout");
      this.$router.push("/login");
    },
    async print(type, exportHeaders = []) {
      this.spin = true;
      this.printModule = this.printModule || (await getPrint()).default;
      this.spin = false;
      let payload = {};
      let list = this.filterCitizens;
      list = _.orderBy(
        list,
        this.listSortBy,
        this.listSortDesc.map((row) => (row ? "desc" : "asc"))
      );
      switch (type) {
        case "通知單":
          payload = {
            list: this.filterCitizens.filter(
              (user) => user.接種地點 && user.接種時間
            ),
            縣市: this.縣市,
            行政區: this.行政區,
            村里: this.村里,
            梯次: this.梯次,
          };
          this.printModule.printNotice(payload);
          break;
        case "列表":
          payload = {
            type,
            headers: exportHeaders,
            list,
            縣市: this.縣市,
            行政區: this.行政區,
            村里: this.村里,
            梯次: this.梯次,
            reserveDate: this.reserveDate,
            hospital: this.hospital,
            condition: this.condition,
          };
          this.printModule.printList(payload);
          break;
        case "CSV":
          payload = {
            type,
            headers: exportHeaders,
            list,
            縣市: this.縣市,
            行政區: this.行政區,
            村里: this.村里,
            梯次: this.梯次,
            reserveDate: this.reserveDate,
            hospital: this.hospital,
            condition: this.condition,
          };
          this.printModule.printCSV(payload);
          break;
        case "同意書":
          break;
      }
    },
    async getCitizens() {
      let params = { 縣市: this.縣市, 行政區: this.行政區, 梯次: this.梯次 };
      if (this.hospital || this.reserveDate) {
        params = {
          接種地點: this.hospital || null,
          接種日期: this.reserveDate || null,
        };
      } else if (
        !params.行政區 &&
        (!params.梯次 || params.梯次 == "造冊市民") &&
        !params.接種地點
      ) {
        if (
          !confirm(
            `你目前搜尋的條件會帶出所有的名單，會造成系統 loading，請確認是否這樣做？`
          )
        ) {
          return false;
        }
      }
      this.citizens = (await this.$axios.get("/citizens", { params })).data;
      this.村里List = _.uniq(this.citizens.map((citizen) => citizen.村里));
      this.村里List = this.村里List.filter((obj) => !!obj);
      this.村里List.unshift({
        text: "全村里",
        value: "",
      });
    },
    async reAssign() {
      if (confirm("確定配發？")) {
        let data = {
          縣市: this.縣市,
          行政區: this.行政區,
          梯次: this.梯次,
          村里: this.村里,
        };
        let result = (await this.$axios.post("/assign", data)).data;
        // this.assignDialog = true;
        alert(`本次配發 ${result.count} 人`);
        await this.getCitizens();
      }
    },
    async changeBox(citizen, key) {
      let data = {};
      data[key] = citizen[key];
      data.editor = this.user.帳號;
      let udpatedCitizen = (
        await this.$axios.patch("/citizen/" + citizen._id, data)
      ).data;

      if (!udpatedCitizen.報到時間) {
        this.$toast.error(
          "請注意！目前網路連線失敗，此報到動作沒有傳送至伺服器！"
        );
      }

      if (key == "已報到") {
        if (data[key]) {
          citizen.報到時間 = udpatedCitizen.報到時間;
          citizen.報到號碼 = udpatedCitizen.報到號碼;
          if (this.$store.state.printer.continuous) {
            this.$store.commit("printAgreement", citizen);
          }
          if (!citizen.地址) {
            this.$toast.warning(`請注意！此人沒有地址，可能不是台南戶籍！`);
          }
          if (!_.isEmpty(citizen.已接種疫苗)) {
            let msg = `請注意！此人接種過疫苗！`;
            _.each(citizen.已接種疫苗, (value, key) => {
              msg += `\n${key} (${dayjs(value).format("YYYY-MM-DD")})`;
            });
            this.$toast.warning(msg);
          }
          this.$forceUpdate();
        } else {
          if (this.$store.state.printer.continuous) {
            this.$store.commit("printAgreement", null);
            // this.$store.commit("printAgreementKid", null);
            // this.$store.commit("printAgreementNovavax", null);
          }
        }
      }
    },
    async getHospitals() {
      this.hospitals = (await this.$axios.get("/hospitals")).data;
      this.$store.commit("setHospitals", this.hospitals);
    },
    keepState() {
      this.$store.commit("keepState", {
        縣市: this.縣市,
        梯次: this.梯次,
        行政區: this.行政區,
        村里: this.村里,
        hospital: this.hospital,
        reserveDate: this.reserveDate,
        keyword: this.keyword,
        condition: this.condition,
      });
    },
  },
  watch: {
    縣市() {
      this.getCitizens();
      this.keepState();
    },
    行政區() {
      this.getCitizens();
      this.keepState();
    },
    梯次() {
      this.getCitizens();
      this.keepState();
    },
    hospital() {
      this.getCitizens();
      this.keepState();
    },
    村里() {
      this.currentPage = 1;
      this.keepState();
    },
    reserveDate() {
      this.getCitizens();
      this.keepState();
    },
    keyword() {
      this.keepState();
    },
    condition() {
      this.keepState();
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .list-table {
  .v-icon.v-icon {
    font-size: 28px;
  }
}
.ellipsis {
  white-space: nowrap;
  max-width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0px;
}
.report {
  text-align: right;
  font-size: 13px;
  font-weight: 500;
  span {
    float: left;
    text-align: right;
    display: inline-block;
    width: calc(100% - 75px);
  }
}
.printer-server {
  position: fixed;
  left: 0;
  top: 0;
  opacity: 0;
}
</style>
