<template>
  <v-dialog v-model="propModel" max-width="800px" persistent>
    <v-card>
      <v-card-title>
        <span class="text-h5">每日數量</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="6">
              <v-select
                v-model="行政區"
                :items="行政區List"
                label="行政區/工作人員分類"
                dense
                hide-details
                outlined
                :disabled="!isManager || !!$store.state.user.醫事單位"
              ></v-select>
            </v-col>
            <v-col cols="6">
              <v-menu
                :close-on-content-click="true"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="reserveDate"
                    label="接種日期"
                    outlined
                    readonly
                    clearable
                    v-bind="attrs"
                    v-on="on"
                    dense
                    hide-details
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="reserveDate"
                  no-title
                  scrollable
                  locale="zh-tw"
                >
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <template>
            <v-data-table
              ref="table"
              :headers="headers"
              :items="hospitals"
              no-data-text="沒有資料"
              multi-sort
              :mobile-breakpoint="0"
              disable-pagination
              hide-default-footer
            ></v-data-table>
          </template>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" text @click="propModel = false"> 關閉 </v-btn>
        <!-- <v-btn color="blue darken-1" text @click="save"> 儲存 </v-btn> -->
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from "lodash";
import dayjs from "dayjs";
export default {
  name: "ReserveReport",
  data: () => ({
    縣市: "台南市",
    hospitals: [],
    行政區List: [],
    縣市List: [],
    行政區: "",
    reserveDate: dayjs().add(1, "day").format("YYYY-MM-DD"),
    headers: [
      { text: "名稱", value: "名稱", align: "right", cellClass: "pr-8" },
      {
        text: "配額上限",
        value: "配額上限",
        align: "right",
        width: 140,
        cellClass: "pr-8",
      },
      {
        text: "已配發",
        value: "已配發",
        align: "right",
        width: 130,
        cellClass: "pr-8",
      },
      {
        text: "列表人數",
        value: "實際列表人數",
        align: "right",
        width: 130,
        cellClass: "pr-8",
      },
      {
        text: "實到人數",
        value: "實際報到人數",
        align: "right",
        width: 130,
        cellClass: "pr-8",
      },
    ],
  }),
  props: ["value", "params", "isManager"],
  created() {
    this.行政區List = _.cloneDeep(this.params.行政區List);
    this.行政區List.shift();
    this.縣市List = _.cloneDeep(this.params.縣市List);
    this.行政區 = this.params.行政區;
  },
  computed: {
    propModel: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    async getCitizens() {
      if (!this.reserveDate) return false;
      let params = {
        縣市: this.縣市,
        // 行政區: this.行政區,
        接種日期: this.reserveDate,
      };

      let citizens = (await this.$axios.get("/citizens", { params })).data;
      this.hospitals = this.hospitals.map((hospital) => {
        let 實際列表人數 = citizens.filter(
          (citizen) => citizen.接種地點 == hospital.名稱
        ).length;
        let 實際報到人數 = citizens.filter(
          (citizen) => citizen.接種地點 == hospital.名稱 && citizen.已報到
        ).length;
        let 配額上限 = hospital.服務時段.reduce((sum, section) => {
          return (sum +=
            section.日期 == this.reserveDate ? section.配額上限 : 0);
        }, 0);
        let 已配發 = hospital.服務時段.reduce((sum, section) => {
          return (sum += section.日期 == this.reserveDate ? section.已配發 : 0);
        }, 0);
        return {
          ...hospital,
          實際列表人數,
          實際報到人數,
          配額上限,
          已配發,
        };
      });
    },
    async getHospitals() {
      this.hospitals = (
        await this.$axios.get("/hospitals", {
          params: _.pickBy(
            { 縣市: this.params.縣市, 行政區: this.行政區 },
            _.identity
          ),
        })
      ).data.filter((hospital) => hospital.名稱);
      this.getCitizens();
    },
  },
  watch: {
    value(open) {
      if (open) this.getHospitals();
    },
    行政區() {
      this.getHospitals();
    },
    reserveDate() {
      this.getCitizens();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>