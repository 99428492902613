<template>
  <v-dialog v-model="propModel" max-width="300px" persistent>
    <v-card>
      <v-card-title>取消預約配發</v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-textarea
              v-model="身分證字號"
              label="身分證字號"
              auto-grow
              outlined
              dense
              hint="可填入多筆，一行一筆身分證字號"
              persistent-hint
            ></v-textarea>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" text @click="reset"> 取消 </v-btn>
        <v-btn color="blue darken-1" text @click="cancel">確認</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "CancelReservation",
  props: ["value"],
  data: () => ({
    身分證字號: "",
  }),
  computed: {
    propModel: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    async cancel() {
      if (!this.身分證字號) {
        alert("身分證字號必須填寫！");
      } else {
        await this.$axios.delete("/reservation/", {
          data: {
            ids: this.身分證字號.replace(/ /g, "").split("\n").join(","),
          },
        });
        alert("取消成功");
        this.$emit("reload");
        this.reset();
      }
    },
    reset() {
      this.身分證字號 = "";
      this.propModel = false;
    },
  },
};
</script>

<style>
</style>