<template>
  <v-dialog v-model="propModel" max-width="320px" persistent>
    <v-card>
      <v-card-title>現場配發疫苗</v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-combobox
              dense
              outlined
              v-model="接種地點"
              item-text="名稱"
              :items="hospitals"
              hide-details
              :disabled="$store.state.user.角色 == '醫事單位'"
              label="請選擇醫療診所"
            ></v-combobox>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              v-model="身分證字號"
              label="身分證字號"
              outlined
              dense
              hide-details
              clearable
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-select
              v-model="接種疫苗"
              :items="vaccineType"
              label="接種疫苗"
              outlined
              dense
              hide-details
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-select
              v-model="接種劑別"
              :items="vaccineDose"
              label="接種劑別"
              outlined
              dense
              hide-details
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-checkbox
              v-model="continuous"
              label="連續配發"
              outlined
              dense
              hide-details
            ></v-checkbox>
          </v-col>
        </v-row>
        <v-row v-if="citizen">
          <v-col>
            <v-alert text color="success">
              <div class="detail">
                <label>報到號碼：</label>
                <div>#{{ citizen.報到號碼 }}</div>
              </div>
              <div class="detail">
                <label>姓名：</label>
                <div>
                  <v-edit-dialog
                    :return-value.sync="citizen.姓名"
                    @save="update(citizen, '姓名')"
                  >
                    {{ citizen.姓名 }}
                    <template v-slot:input>
                      <v-text-field
                        class="mb-2"
                        v-model="citizen.姓名"
                        single-line
                        autofocus
                        hint="請填入姓名"
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                </div>
              </div>
              <div class="detail">
                <label>身分證字號：</label>
                <div>
                  <v-edit-dialog
                    :return-value.sync="citizen.身分證字號"
                    @save="update(citizen, '身分證字號')"
                  >
                    {{ citizen.身分證字號 }}
                    <template v-slot:input>
                      <v-text-field
                        class="mb-2"
                        v-model="citizen.身分證字號"
                        single-line
                        autofocus
                        hint="請填入身分證字號"
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                </div>
              </div>
              <div class="detail">
                <label>出生年月日：</label>
                <div>
                  <v-edit-dialog
                    :return-value.sync="citizen.出生年月日"
                    @save="update(citizen, '出生年月日')"
                  >
                    {{ citizen.出生年月日 }}
                    <template v-slot:input>
                      <v-text-field
                        class="mb-2"
                        v-model="citizen.出生年月日"
                        single-line
                        autofocus
                        hint="請填入生日(民國年)"
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                </div>
              </div>
              <div class="detail">
                <label>電話：</label>
                <div>
                  <v-edit-dialog
                    :return-value.sync="citizen.電話"
                    @save="update(citizen, '電話')"
                  >
                    {{ citizen.電話 }}
                    <template v-slot:input>
                      <v-text-field
                        class="mb-2"
                        v-model="citizen.電話"
                        single-line
                        autofocus
                        hint="請填入電話"
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                </div>
              </div>
            </v-alert>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" text @click="reset"> 關閉 </v-btn>
        <v-btn color="blue darken-1" text @click="assign">配發</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import dayjs from "dayjs";
import { vaccineType, vaccineDose } from "@/config";
export default {
  name: "OnSiteAssign",
  props: ["value", "params"],
  data: () => ({
    身分證字號: "",
    接種地點: "",
    接種時間: dayjs().format("YYYY-MM-DD"),
    接種疫苗: "",
    梯次: "現場殘劑",
    行政區: "中央預約系統",
    hospitals: [],
    vaccineType,
    vaccineDose,
    continuous: false,
    citizen: null,
    接種劑別: null,
  }),
  computed: {
    propModel: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  created() {
    if (this.$store.state.user.醫事單位) {
      this.接種地點 = this.$store.state.user.醫事單位;
    }
  },
  methods: {
    async getHospitals() {
      let query = {
        縣市: this.params.縣市,
        行政區: this.params.行政區,
      };
      this.hospitals = (
        await this.$axios.get("/hospitals", {
          params: _.pickBy(query, _.identity),
        })
      ).data
        .filter((hospital) => hospital.名稱)
        .map((hospital) => hospital.名稱);
      this.接種地點 = this.params.hospital;
    },
    async assign() {
      if (!this.身分證字號) {
        alert("身分證字號必須填寫！");
        return false;
      }
      if (!this.接種地點) {
        alert("接種地點必須填寫！");
        return false;
      }
      if (!this.接種疫苗) {
        alert("接種疫苗必須填寫！");
        return false;
      }
      let data = {
        身分證字號: this.身分證字號,
        接種地點: this.接種地點,
        接種疫苗: this.接種疫苗,
        梯次: this.梯次,
        接種劑別: this.接種劑別,
      };
      let finded = (
        await this.$axios.get("/population", {
          params: {
            身分證字號: this.身分證字號,
            梯次: this.梯次,
            行政區: this.行政區,
          },
        })
      ).data;
      if (!finded) {
        this.$toast.error("此民眾並非台南市籍，請再次確認資料！");
        return false;
      }

      let age = dayjs().diff(
        dayjs(parseInt(finded.出生年月日 + 19110000).toString()),
        "years"
      );

      // if (
      //   age >= 12 &&
      //   age < 18 &&
      //   this.接種疫苗 != "CoV_BioNTech" &&
      //   this.接種疫苗 != "CoV_Moderna"
      // ) {
      //   this.$toast.error("12-18 歲僅能施打 BNT 和莫德納疫苗！");
      //   return false;
      // }
      // if (
      //   age >= 12 &&
      //   age < 18 &&
      //   this.接種劑別 == "B" &&
      //   this.接種疫苗 != "CoV_BioNTech"
      // ) {
      //   this.$toast.error("12-18 歲追加劑僅能施打 BNT！");
      //   return false;
      // }
      // if (
      //   age < 12 &&
      //   age > 5 &&
      //   this.接種疫苗 != "CoV_BioNTech_Kid" &&
      //   this.接種疫苗 != "CoV_bModerna_BA4/5" &&
      //   this.接種疫苗 != "CoV_Novavax_XBB" &&
      //   this.接種疫苗 != "CoV_Moderna_XBB"
      // ) {
      //   this.$toast.error("兒童僅能施打兒童 BNT 和雙價 BA4/5 莫德納！");
      //   return false;
      // }
      // if (age == 5 && this.接種疫苗 != "CoV_BioNTech_Kid") {
      //   this.$toast.error("5 歲幼童僅能施打兒童 BNT！");
      //   return false;
      // }
      if (age < 12 && this.接種疫苗 != "CoV_Moderna_XBB") {
        this.$toast.error("12 歲以下兒童僅能施打莫德納XBB！");
        return false;
      }
      // if (age < 18 && this.接種疫苗 == "CoV_bModerna_BA1") {
      //   this.$toast.error("莫德納次世代僅能開放 18 歲以上施打！");
      //   return false;
      // }

      // if (finded.出生年月日 > 930000) {
      //   this.$toast.error(
      //     "此民眾非民國92年12月31日(含)以前出生者，請再次確認資料！"
      //   );
      //   return false;
      // }
      if (!_.isEmpty(finded.已接種疫苗)) {
        let msg = `請注意！此人接種過疫苗！`;
        _.each(finded.已接種疫苗, (value, key) => {
          msg += `\n${key} (${dayjs(value).format("YYYY-MM-DD")})`;
        });
        this.$toast.warning(msg);
      }

      try {
        this.citizen = (await this.$axios.post("/onsite", data)).data;
        this.$toast.success(
          `[#${this.citizen.報到號碼}] ${this.citizen.姓名} ${this.citizen.出生年月日} 配發成功！`
        );
        if (this.$store.state.printer.continuous) {
          this.$store.commit("printAgreement", this.citizen);
        }
        this.$emit("reload");
        if (!this.continuous) {
          this.reset();
        }
      } catch (error) {
        console.log(error);
        this.$toast.error("配發失敗！請提相關資訊給智發中心。");
      }
    },
    reset() {
      this.身分證字號 = "";
      this.citizen = null;
      this.propModel = false;
    },
    async update(citizen, key) {
      let data = {};
      data[key] = citizen[key];
      data.editor = this.$store.state.user.帳號;
      await this.$axios.patch("/citizen/" + citizen._id, data);
      this.$emit("reload");
    },
  },
  watch: {
    value(open) {
      if (open) {
        this.$store.commit("scan", "");
        this.getHospitals();
      } else this.reset();
    },
    "$store.state.scan"(value) {
      if (value && this.propModel) {
        this.身分證字號 = value;
        this.$store.commit("scan", "");
        this.assign();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.detail {
  display: flex;
  font-weight: 500;
  label {
    width: 98px;
    font-weight: 400;
  }
}
::v-deep .v-small-dialog__activator__content {
  min-width: 60px;
  text-decoration: underline;
}
</style>
