<template>
  <v-dialog v-model="propModel" max-width="300px" persistent>
    <v-card>
      <v-card-title>匯出列表欄位</v-card-title>
      <v-card-text>
        <v-checkbox
          class="mt-0 pt-0"
          v-for="value of allHeaders"
          :key="value"
          v-model="exportHeaders"
          :label="value"
          :value="value"
          dense
          hide-details
        ></v-checkbox>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" text @click="propModel = false"> 取消 </v-btn>
        <v-btn
          color="blue darken-1"
          text
          @click="
            propModel = false;
            $emit('print', 'CSV', exportHeaders);
          "
          >匯出 CSV</v-btn
        >
        <v-btn
          color="blue darken-1"
          text
          @click="
            propModel = false;
            $emit('print', '列表', exportHeaders);
          "
          >匯出 PDF</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import _ from "lodash";
export default {
  name: "PrintList",
  props: ["value", "allHeaders", "defaultHeaders"],
  data: () => ({
    exportHeaders: [],
  }),
  computed: {
    propModel: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  created() {
    let list = _.cloneDeep(this.defaultHeaders);
    list = _.without(
      list,
      "已報到",
      "醫生不同意"
      // "接種地點",
      // "報到時間",
      // "報到號碼"
    );

    this.exportHeaders = list;
  },
  methods: {},
  watch: {},
};
</script>

<style>
</style>