<template>
  <v-app id="inspire">
    <v-main>
      <v-container fluid fill-height>
        <v-layout justify-center>
          <v-card class="elevation-6 ma-12" width="80%" max-width="1200px">
            <v-toolbar dark color="primary" class="justify-center">
              <v-toolbar-title> 台南市政府疫苗預約服務 </v-toolbar-title>
              <span class="ml-auto text-body-2 pointer" @click="logout"
                >[登出]</span
              >
            </v-toolbar>
            <v-card-text>
              <v-row>
                <v-col :cols="3" :offset="3"
                  ><v-text-field
                    v-model="input.身分證字號"
                    label="身分證字號"
                    placeholder="例: A123456789"
                    hide-details
                    type="text"
                    v-on:keyup.enter="search"
                  ></v-text-field
                ></v-col>
                <v-col :cols="3">
                  <v-text-field
                    v-model="input.出生年月日"
                    label="出生年月日"
                    placeholder="例: 520606"
                    hide-details
                    type="text"
                    v-on:keyup.enter="search"
                  ></v-text-field>
                </v-col>
                <v-col :cols="3">
                  <v-btn color="primary" class="mr-2" @click="search"
                    >查詢</v-btn
                  >
                  <v-btn color="error" @click="reset">清空</v-btn>
                </v-col>
              </v-row>
              <v-row v-if="errorMsg">
                <v-col :cols="6" :offset="3">
                  <v-alert text type="error" v-html="errorMsg"> </v-alert>
                </v-col>
              </v-row>
              <v-row v-if="warningMsg">
                <v-col :cols="6" :offset="3">
                  <v-alert text type="warning" v-html="warningMsg"> </v-alert>
                </v-col>
                <v-col :cols="3" v-if="user.接種地點 && user.接種時間">
                  <v-btn color="warning" class="mr-2 mt-2" @click="cancel"
                    >取消預約</v-btn
                  >
                </v-col>
              </v-row>
              <v-row v-if="successMsg">
                <v-col :cols="6" :offset="3">
                  <v-alert text type="success" v-html="successMsg"> </v-alert>
                </v-col>
              </v-row>
              <v-row v-if="user.姓名">
                <v-col :cols="6" :offset="3">
                  <v-text-field
                    v-model="user.電話"
                    label="電話"
                    placeholder="無電話"
                    hide-details
                    type="text"
                  ></v-text-field>
                </v-col>
                <v-col :cols="3">
                  <v-btn color="primary" class="mr-2" @click="update"
                    >更新</v-btn
                  >
                  <v-btn
                    v-if="user.接種時間 && user.接種地點"
                    :disabled="!isPhoneValid"
                    color="warning"
                    @click="sms"
                    >發送簡訊</v-btn
                  >
                </v-col>
              </v-row>
              <v-row v-if="smsSuccessMsg">
                <v-col :cols="6" :offset="3">
                  <v-alert text type="success" v-html="smsSuccessMsg">
                  </v-alert>
                </v-col>
              </v-row>
              <v-row v-if="smsErrorMsg">
                <v-col :cols="6" :offset="3">
                  <v-alert text type="error" v-html="smsErrorMsg"> </v-alert>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-divider></v-divider>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="text-center">
                  <v-data-table
                    :headers="headers"
                    :items="sections"
                    no-data-text="沒有可預約時段"
                    :mobile-breakpoint="0"
                    :footer-props="{
                      itemsPerPageText: '每頁幾筆',
                      itemsPerPageOptions: [10, 30, -1],
                    }"
                  >
                    <template v-slot:item.開始時間="{ item }">
                      {{ item.開始時間 }}~{{ item.結束時間 }}
                    </template>
                    <template v-slot:item.配額上限="{ item }">
                      {{ parseInt(item.配額上限) - parseInt(item.已配發) }}
                    </template>
                    <template v-slot:item.預約="{ item }">
                      <v-btn
                        small
                        color="primary"
                        @click="reserve(item)"
                        :disabled="item.配額上限 <= item.已配發"
                        >預約</v-btn
                      >
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import dayjs from "dayjs";
const 梯次 = "造冊市民";
// const 行政區 = "地方自辦";

export default {
  name: "Service",
  data: () => ({
    input: {
      身分證字號: "",
      出生年月日: "",
      梯次,
      // 行政區,
    },
    跨區預約: true,
    user: {},
    headers: [
      { text: "醫事單位", value: "名稱" },
      { text: "日期", value: "日期" },
      { text: "預約時段", value: "開始時間" },
      { text: "疫苗種類", value: "疫苗種類" },
      { text: "地址", value: "地址", sortable: false },
      { text: "電話", value: "電話", sortable: false },
      { text: "剩餘配額", value: "配額上限", sortable: false },
      { text: "預約", value: "預約", sortable: false },
    ],
    sections: [],
    errorMsg: "",
    successMsg: "",
    warningMsg: "",
    smsSuccessMsg: "",
    smsErrorMsg: "",
  }),
  computed: {
    isPhoneValid() {
      return /^[09]{2}[0-9]{8}$/.test(this.user.電話);
    },
  },
  created() {
    if (!this.$store.state.service) this.$router.push("/login");
  },
  methods: {
    async cancel() {
      if (confirm("確認取消民眾已預約的時間？")) {
        await this.$axios.delete("/reservation/" + this.user.身分證字號);
        this.clear();
        this.successMsg = "已取消預約！";
      }
    },
    async update() {
      let data = {
        editor: this.$store.state.user.帳號,
        電話: this.user.電話,
      };
      await this.$axios.patch("/citizen/" + this.user._id, data);
      this.smsSuccessMsg = "更新成功！";
    },
    async sms() {
      try {
        await this.$axios.post("/smsReserveInfo", { _id: this.user._id });
        this.smsSuccessMsg = "已發送！";
        this.smsErrorMsg = "";
      } catch (e) {
        this.smsSuccessMsg = "";
        this.smsErrorMsg = "發送失敗，請檢查民眾的手機號碼與預約記錄！";
      }
    },
    async reserve(section) {
      if (!confirm(`確認預約「${section.名稱}」`)) return false;
      if (this.user.接種地點 && this.user.接種時間) {
        await this.$axios.delete("/reservation/" + this.user.身分證字號);
      }
      let record = (
        await this.$axios.post(`/reservation`, {
          身分證字號: this.user.身分證字號,
          名稱: section.名稱,
          日期: section.日期,
          開始時間: section.開始時間,
          結束時間: section.結束時間,
          跨區預約: this.跨區預約,
          梯次,
        })
      ).data;
      this.sections = [];
      this.user.接種地點 = record.接種地點;
      this.user.接種時間 = record.接種時間;
      this.clear();
      this.successMsg = `已預約完成「${record.接種地點}」${record.接種時間}！`;
    },
    //
    async search() {
      this.clear();
      this.sections = [];
      this.input.身分證字號 = this.input.身分證字號.toUpperCase();
      let user = (await this.$axios.get("/population", { params: this.input }))
        .data;
      if (!user) {
        this.errorMsg = "此民眾並非台南市籍，請再次確認資料！";
        return false;
      }

      this.user = user;

      if (user.出生年月日 > 930000) {
        this.errorMsg =
          "此民眾非民國92年12月31日(含)以前出生者，請再次確認資料！";
        return false;
      }

      if (!_.isEmpty(user.已接種疫苗)) {
        this.warningMsg = `請注意！此人接種過疫苗！`;
        _.each(user.已接種疫苗, (value, key) => {
          this.warningMsg += `<li>${key} (${dayjs(value).format(
            "YYYY-MM-DD"
          )})</li>`;
        });
      }
      if (user.接種地點 || user.接種時間) {
        this.warningMsg = `此民眾已預約「${user.接種地點}」${user.接種時間}！`;
      }

      this.sections = (
        await this.$axios.post("/reserveSections", {
          ...this.input,
          跨區預約: this.跨區預約,
        })
      ).data;

      this.sections = this.sections.map((section) => ({
        ...section,
        額滿: section.已配發 >= section.配額上限,
      }));

      // if (this.$store.state.user.醫事單位) {
      //   this.sections = this.sections.filter(
      //     (section) => section.名稱 == this.$store.state.user.醫事單位
      //   );
      // } else {
      //   this.sections = this.sections.filter((section) => {
      //     return (
      //       !屏蔽醫事.includes(section.名稱) &&
      //       !屏蔽醫事.includes(section.行政區)
      //     );
      //   });
      // }

      this.sections = _.sortBy(this.sections, ["額滿", "日期", "開始時間"]);

      if (this.sections.length) {
        this.user = user;
      } else {
        this.warningMsg = "目前無任何可預約時段！";
      }
    },
    reset() {
      this.input = {
        身分證字號: "",
        出生年月日: "",
        梯次,
        // 行政區,
      };
      this.user = {};
      this.sections = [];
      this.clear();
    },
    clear() {
      this.errorMsg = "";
      this.warningMsg = "";
      this.successMsg = "";
      this.smsSuccessMsg = "";
      this.smsErrorMsg = "";
    },
    logout() {
      this.$store.commit("logout");
      this.$router.push("/login");
    },
  },
};
</script>

<style lang="scss" scoped>
</style>