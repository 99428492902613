<template>
  <div>
    <div class="page">
      <div class="t1">
        <span class="l1">{{ citizen.姓名 }}</span>
      </div>
      <div class="t11">
        <span class="l2">{{ citizen.身分證字號 }}</span>
      </div>
      <div class="t3">
        <span class="l3">{{ birth.年 }}</span>
        <span class="l4">{{ birth.月 }}</span>
        <span class="l5">{{ birth.日 }}</span>
        <span class="l6">{{ citizen.電話 }}</span>
      </div>
      <!-- <div class="t3">
        <span class="l7">{{ citizen.縣市 }}</span>
        <span class="l8">{{ citizen.行政區域 || citizen.行政區 || "" }}</span>
        <span class="l9">{{ citizen.地址 || "" }}</span>
      </div> -->
      <!-- <div class="t4">
        <span class="l10">{{ citizen.身分證字號 }}</span>
      </div> -->
      <div class="t5">
        <span class="l11">{{ dayjs().format("YYYY") }}</span>
        <span class="l12">{{ dayjs().format("MM") }}</span>
        <span class="l13">{{ dayjs().format("DD") }}</span>
      </div>
      <div class="t6">
        <span class="l14">{{
          $store.state.hospitalCodes[citizen.接種地點] || ""
        }}</span>
      </div>
      <div class="t7">
        <span class="l15">#{{ citizen.報到號碼 }}</span>
        <span class="l16">{{
          dayjs(citizen.報到時間).format("MM/DD HH:mm")
        }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
export default {
  name: "PaperKid",
  data: () => ({
    test: "sss",
    dayjs,
  }),
  computed: {
    citizen() {
      return this.$store.state.printer.citizenKid;
    },
    birth() {
      let 生日 = (this.citizen.出生年月日 * 1 + 19110000).toString();
      let 年 = 生日.substring(0, 4);
      let 月 = 生日.substring(4, 6);
      let 日 = 生日.substring(6);
      return { 年, 月, 日 };
    },
  },
};
</script>
<style lang="scss" scoped>
.page {
  width: 210mm; /*隨著紙張大小異動*/
  height: 297mm; /*隨著紙張大小異動*/
  padding: 20mm;
  margin: 10mm auto;
  border: 1px #d3d3d3 solid;
  background: white;
  // background-image: url("/t.png") !important;
  // background-size: 210mm 297mm !important;
  // background-repeat: no-repeat !important;
  // background-position: center center !important;
}

@page {
  size: A4;
  margin: 0;
}
@media print {
  .page {
    font-size: 18px;
    margin: 0;
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    page-break-after: always;

    div {
      position: relative;
      width: 100%;
    }

    span {
      position: absolute;
    }
    .t1 {
      top: 115mm;
      .l1 {
        left: 33mm;
      }
    }
    .t11 {
      top: 125mm;
      .l2 {
        left: 54mm;
      }
    }
    .t2 {
      top: 137mm;
    }
    .t3 {
      margin-top: 134.2mm;
      .l3 {
        left: 35mm;
      }
      .l4 {
        left: 57mm;
      }
      .l5 {
        left: 77mm;
      }
      .l6 {
        left: 129mm;
      }
    }
    .t5 {
      margin-top: 240.3mm;
      .l11 {
        left: 17mm;
      }
      .l12 {
        left: 40mm;
      }
      .l13 {
        left: 59mm;
      }
    }
    .t6 {
      margin-top: 251mm;
      .l14 {
        left: 45mm;
      }
    }
    .t7 {
      margin-top: -263mm;
      .l15 {
        left: -9mm;
        font-size: 20px;
      }
      .l16 {
        left: 10mm;
        font-size: 20px;
      }
    }
  }
}
</style>