<template>
  <v-dialog v-model="propModel" max-width="300px" persistent>
    <v-card>
      <v-card-title>匯入戶籍檔</v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-file-input
              v-model="file"
              prepend-icon=""
              accept=".xls"
              label="檔案"
              outlined
              dense
              persistent-hint
              hint="請先移除密碼"
            ></v-file-input>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" text @click="reset"> 取消 </v-btn>
        <v-btn color="blue darken-1" text @click="dataImport">匯入</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import _ from "lodash";
export default {
  name: "PopulationImport",
  props: ["value"],
  data: () => ({
    file: null,
  }),
  computed: {
    propModel: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    async dataImport() {
      if (this.file) {
        let formData = new FormData();
        formData.append("file", this.file, this.file.name);
        let result = (await this.$axios.post("/importPopulation", formData))
          .data;
        alert(`檔案內筆數: ${result.total}\n成功匯入筆數: ${result.insert}`);
        this.reset();
      }
    },
    reset() {
      this.file = null;
      this.propModel = false;
    },
  },
};
</script>

<style></style>
