import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import axios from "axios";
import Print from "@/libs/printer.js";
import Toast from "vue-toastification";
import { VueReCaptcha } from "vue-recaptcha-v3";
import "vue-toastification/dist/index.css";

import { APIURL } from "@/../env.js";

axios.interceptors.request.use(
  function(config) {
    config.url = config.alone ? config.url : APIURL + config.url;
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

Vue.use(VueReCaptcha, { siteKey: "6LfvCh8mAAAAAESHIYVZm2H9o8zOvU1mRXDZCJl4" });
Vue.use(Toast, {
  position: "bottom-center",
  timeout: 10000,
  pauseOnHover: false,
  hideProgressBar: true,
  bodyClassName: ["toast-text"],
});
Vue.use(Print);

Vue.config.productionTip = false;
Vue.prototype.$axios = axios;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
