<template>
  <v-app id="inspire">
    <v-main>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-card class="elevation-6" width="400">
            <v-toolbar dark color="primary" class="justify-center">
              <v-toolbar-title>台南市政府疫苗施打通報系統</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-text-field
                v-model="input.account"
                prepend-icon="mdi-account"
                label="帳號"
                type="text"
                v-on:keyup.enter="login"
              ></v-text-field>
              <v-text-field
                v-model="input.password"
                prepend-icon="mdi-lock"
                label="密碼"
                type="password"
                v-on:keyup.enter="login"
              ></v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="login">登入</v-btn>
            </v-card-actions>
          </v-card>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "Login",
  data: () => ({
    input: {
      account: "",
      password: "",
    },
    user: {},
  }),
  methods: {
    // async recaptcha() {
    //   // (optional) Wait until recaptcha has been loaded.
    //   await this.$recaptchaLoaded();

    //   // Execute reCAPTCHA with action "login".
    //   const token = await this.$recaptcha("login");
    //   console.log(token);
    //   // Do stuff with the received token.
    // },
    async login() {
      try {
        const token = await this.$recaptcha("login");
        this.input.token = token;
        let user = (await this.$axios.post("/login", this.input)).data;
        if (user.角色 == "預約客服") {
          this.$store.commit("service", user);
          this.$router.push("/Service");
        } else if (user.角色 == "NIIS") {
          this.$store.commit("service", user);
          this.$router.push("/Niis");
        } else {
          this.$store.commit("login", user);
          this.$router.push("/Dashboard");
        }
      } catch (error) {
        alert("帳號密碼錯誤");
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
