<template>
  <v-app id="inspire">
    <v-main>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-card class="elevation-6" width="400">
            <v-toolbar dense dark color="warning">
              <v-toolbar-title>COVID NIIS 接種紀錄快查</v-toolbar-title>
              <v-spacer></v-spacer>
              <span class="ml-auto text-body-2 pointer" @click="logout"
                >[登出]</span
              >
            </v-toolbar>
            <v-card-text>
              <v-text-field
                v-model="身分證字號"
                label="身分證字號"
                type="text"
                hide-details
                clearable
                @click:clear="clear"
              ></v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn block color="warning" @click="search">查詢</v-btn>
            </v-card-actions>
            <div v-if="records.length" class="text-center">
              <div v-for="record in records" class="ma-2 text-h6 warning--text">
                {{ record.接種日期 | formatDate }} {{ record.疫苗劑別 }}
              </div>
            </div>
          </v-card>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import barcodeReader from "@wrurik/barcode-scanner";
export default {
  name: "Niis",
  data: () => ({
    身分證字號: "",
    records: [],
  }),
  created() {
    if (!this.$store.state.service) this.$router.push("/login");
    else barcodeReader.onScan(this.scanHandler);
  },
  methods: {
    scanHandler(value, e) {
      this.身分證字號 = value;
      this.search();
    },
    async search() {
      if (!/^[A-Z](1|2)\d{8}$/i.test(this.身分證字號)) {
        alert("身分證字號錯誤！");
        return false;
      }
      this.records = (
        await this.$axios.get(`/niisQuery/${this.身分證字號}`)
      ).data;
    },
    logout() {
      this.$store.commit("logout");
      this.$router.push("/login");
    },
    clear() {
      this.身分證字號 = "";
      this.records = [];
    },
  },
  filters: {
    formatDate(value) {
      value = (parseInt(value) + 19110000).toString();
      return `${value.slice(0, 4)}-${value.slice(4, 6)}-${value.slice(6, 8)}`;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>