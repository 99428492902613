<template>
  <v-dialog v-model="propModel" max-width="800px" persistent>
    <v-card>
      <v-card-title>加入施打名冊</v-card-title>
      <v-card-text>
        <v-row>
          <v-col :cols="4">
            <v-select
              v-model="citizen.梯次"
              :items="梯次List"
              label="梯次"
              dense
              hide-details
              outlined
            ></v-select>
          </v-col>
          <v-col :cols="4">
            <v-select
              v-model="citizen.類別"
              :items="類別List"
              label="類別"
              dense
              hide-details
              outlined
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="8">
            <v-select
              v-model="citizen.行政區"
              :items="行政區List"
              label="行政區/工作人員分類"
              dense
              hide-details
              outlined
            ></v-select>
          </v-col>
          <v-col>
            <v-text-field
              v-model="citizen.村里"
              label="村里/單位"
              outlined
              dense
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              v-model="citizen.姓名"
              label="姓名"
              outlined
              dense
              hide-details
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model="citizen.身分證字號"
              label="身分證字號"
              outlined
              dense
              hide-details
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model="citizen.出生年月日"
              label="出生年月日"
              placeholder="例：540322"
              outlined
              dense
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col :cols="8">
            <v-text-field
              v-model="citizen.地址"
              label="本地住所地址"
              outlined
              dense
              hide-details
            ></v-text-field>
          </v-col>
          <v-col :cols="4">
            <v-text-field
              v-model="citizen.電話"
              label="電話"
              outlined
              dense
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          text
          @click="
            reset();
            propModel = false;
          "
        >
          取消
        </v-btn>
        <v-btn color="blue darken-1" text @click="save">新增</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { 類別List } from "@/config.js";
import * as Validator from "validatorjs";
import _ from "lodash";
const rules = {
  身分證字號: "required",
  姓名: "required",
  出生年月日: "required",
  縣市鄉鎮: "required",
  地址: "required",
  電話: "required",
  梯次: "required",
  縣市: "required",
  行政區: "required",
  村里: "required",
};
export default {
  name: "Citizen",
  props: ["value", "params"],
  data: () => ({
    citizen: {},
    縣市List: [],
    類別List,
  }),
  created() {
    this.縣市List = _.cloneDeep(this.params.縣市List);
    this.reset();
  },
  computed: {
    梯次List() {
      let list = _.cloneDeep(this.params.梯次List);
      list.shift();
      return list;
    },
    行政區List() {
      let list = _.cloneDeep(this.params.行政區List);
      list.shift();
      return list;
    },
    propModel: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    async save() {
      this.citizen.縣市鄉鎮 = this.citizen.縣市 + this.citizen.行政區;
      let validation = new Validator(this.citizen, rules);
      if (validation.fails()) {
        console.log(validation.errors.all());
        alert("所有欄位皆必須填寫！");
      } else {
        try {
          await this.$axios.post("/citizen", this.citizen);
          alert("增加成功");
          this.$emit("reload");
          this.propModel = false;
        } catch (e) {
          alert("新增失敗，此梯次已存在該人員，請檢查篩選條件");
        }
      }
    },
    reset() {
      this.citizen = {
        身分證字號: "",
        姓名: "",
        出生年月日: "",
        縣市鄉鎮: "",
        地址: "",
        年齡: "",
        類別: "",
        是否為原住民: "0",
        電話: "",
        不在籍: false,
        額外加入: true,
        梯次: this.params.梯次 || "",
        縣市: this.params.縣市 || "",
        行政區: this.params.行政區 || "",
        村里: this.params.村里 || "",
      };
    },
  },
  watch: {
    value(open) {
      if (open) this.reset();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>