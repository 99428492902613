exports.vaccineType = [
  { text: "AZ", value: "CoV_AZ" },
  { text: "莫德納", value: "CoV_Moderna" },
  { text: "高端", value: "CoV_Medigen" },
  { text: "BNT", value: "CoV_BioNTech" },
  { text: "兒童BNT", value: "CoV_BioNTech_Kid" },
  { text: "幼童BNT", value: "CoV_BioNTech_Baby" },
  { text: "幼童莫德納", value: "CoV_Moderna_Kid" },
  { text: "Novavax", value: "CoV_Novavax" },
  { text: "莫德納-雙價(BA.1)次世代疫苗", value: "CoV_bModerna_BA1" },
  { text: "莫德納-雙價(BA.4/5)次世代疫苗", value: "CoV_bModerna_BA4/5" },
  { text: "幼兒莫德納-雙價(BA.4/5)", value: "CoV_bModerna_BA4/5-Booster" },
  { text: "莫德納XBB", value: "CoV_Moderna_XBB" },
  { text: "Novavax XBB", value: "CoV_Novavax_XBB" },
];

exports.vaccineDose = [
  { text: "第一劑", value: 1 },
  { text: "第二劑", value: 2 },
  { text: "追加劑(B)", value: "B" },
  { text: "第四劑(B2)", value: "B2" },
  { text: "第五劑(B3)", value: "B3" },
  { text: "第六劑(B4)", value: "B4" },
  { text: "第七劑(B5)", value: "B5" },
  { text: "基礎加強劑(3)", value: "3" },
];

exports.行政區List = [
  { text: "全部", value: "" },
  "造冊學生",
  "中央預約系統",
  "特殊職業別",
  "地方自辦",
  "地方自辦分配",
  "虛擬場",
  "中西區",
  "東區",
  "南區",
  "北區",
  "安平區",
  "安南區",
  "永康區",
  "歸仁區",
  "新化區",
  "左鎮區",
  "玉井區",
  "楠西區",
  "南化區",
  "仁德區",
  "關廟區",
  "龍崎區",
  "官田區",
  "麻豆區",
  "佳里區",
  "西港區",
  "七股區",
  "將軍區",
  "學甲區",
  "北門區",
  "新營區",
  "後壁區",
  "白河區",
  "東山區",
  "六甲區",
  "下營區",
  "柳營區",
  "鹽水區",
  "善化區",
  "大內區",
  "山上區",
  "新市區",
  "安定區",
];
exports.類別List = [
  {
    value: "C02B",
    text: "衛生單位第一線防疫人員",
  },
  {
    value: "C02D",
    text: "實際執行居家檢疫與居家隔離者關懷服務工作人員",
  },
  {
    value: "C02E",
    text: "實際執行救災、救護人員",
  },
  {
    value: "C03D",
    text: "防疫旅宿實際執行居家檢疫工作之第一線人員",
  },
  {
    value: "C03E",
    text: "第一類至第三類實施對象同住者",
  },
  {
    value: "C04A",
    text: "警察",
  },
  {
    value: "C05A",
    text: "長照機構之受照顧者",
  },
  {
    value: "C05B",
    text: "長照機構中相關工作人員",
  },
  {
    value: "C08",
    text: "65歲以上長者",
  },
  {
    value: "C10",
    text: "50-64歲成人",
  },
];
exports.allHeaders = [
  "序號",
  "姓名",
  "身分證字號",
  "出生年月日",
  // "地址",
  "電話",
  // "年齡",
  // "類別",
  // "縣市",
  "行政區",
  "村里",
  // "有意願",
  // "需接送",
  // "需到府",
  // "已通知",
  "已接種疫苗",
  "接種地點",
  "接種時間",
  "接種疫苗",
  "已報到",
  "報到時間",
  "報到號碼",
  "醫生不同意",
  // "不在籍",
  // "梯次",
];
exports.conditionList = [
  "全部",
  { divider: true },
  "已報到",
  "未報到",
  "醫生不同意",
  { divider: true },
  "未配發",
  "已配發",
  { divider: true },
  "有意願",
  "無意願",
  "未確認意願",
  "需接送",
  "已通知",
  "已配發但未通知",
];
exports.conditionFunction = {
  全部: (list) => {
    return list;
  },
  未確認意願: (list) => {
    return list.filter((citizen) => citizen.有意願 === null);
  },
  有意願: (list) => {
    return list.filter((citizen) => citizen.有意願);
  },
  無意願: (list) => {
    return list.filter((citizen) => citizen.有意願 === false);
  },
  未配發: (list) => {
    return list.filter(
      (citizen) => citizen.有意願 && !citizen.接種地點 && !citizen.接種時間
    );
  },
  需接送: (list) => {
    return list.filter((citizen) => citizen.需接送);
  },
  需到府: (list) => {
    return list.filter((citizen) => citizen.需到府);
  },
  已配發: (list) => {
    return list.filter((citizen) => citizen.接種地點 && citizen.接種時間);
  },
  已配發但未通知: (list) => {
    return list.filter(
      (citizen) => citizen.接種地點 && citizen.接種時間 && !citizen.已通知
    );
  },
  已通知: (list) => {
    return list.filter((citizen) => citizen.已通知);
  },
  已報到: (list) => {
    return list.filter((citizen) => citizen.已報到);
  },
  未報到: (list) => {
    return list.filter((citizen) => !citizen.已報到);
  },
  醫生不同意: (list) => {
    return list.filter((citizen) => citizen.醫生不同意);
  },
};
exports.role = {
  管理者: {
    headers: [
      { text: "序號", value: "序號", sortable: false },
      { text: "姓名", value: "姓名", sortable: false },
      { text: "身分證字號", value: "身分證字號", sortable: false },
      { text: "出生年月日", value: "出生年月日", sortable: false },
      { text: "電話", value: "電話", sortable: false },
      // { text: "行政區", value: "行政區", sortable: false },
      // { text: "村里", value: "村里", sortable: false },
      // { text: "有意願", value: "有意願", sortable: true, align: "center" },
      // { text: "需接送", value: "需接送", sortable: true, align: "center" },
      // { text: "需到府", value: "需到府", sortable: true, align: "center" },
      { text: "接種劑別", value: "接種劑別", sortable: true },
      { text: "最近接種疫苗", value: "已接種疫苗", sortable: true },
      { text: "接種地點", value: "接種地點", sortable: true },
      { text: "接種時間", value: "接種時間", sortable: true },
      { text: "接種疫苗", value: "接種疫苗", sortable: true },
      // { text: "已通知", value: "已通知", sortable: true, align: "center" },
      { text: "已報到", value: "已報到", sortable: true, align: "center" },
      { text: "報到時間", value: "報到時間", sortable: true, align: "center" },
      { text: "報到號碼", value: "報到號碼", sortable: true, align: "center" },
      {
        text: "醫生不同意",
        value: "醫生不同意",
        sortable: true,
        align: "center",
      },
    ],
    permit: {
      btnSystemLog: true,
      changeDistrict: true,
      inlineEdit: true,
      btnAssign: true,
      btnCancelReservation: true,
      btnOnSiteAssignDialog: true,
      btnSetHospital: true,
      btnAddCitizen: true,
      btnPrintContact: true,
      btnPrintNotice: true,
      btnNIIS: true,
      btnReserveReport: true,
      btnListImport: true,
      btnNiisImport: true,
      btnPopulationImport: true,
      btnHospitalImport: true,
      btnListDelete: false,
      btnPrintAgreement: true,
    },
  },
  衛生局: {
    headers: [
      { text: "序號", value: "序號", sortable: false },
      { text: "姓名", value: "姓名", sortable: false },
      { text: "身分證字號", value: "身分證字號", sortable: false },
      { text: "出生年月日", value: "出生年月日", sortable: false },
      { text: "電話", value: "電話", sortable: false },
      // { text: "行政區", value: "行政區", sortable: false },
      // { text: "村里", value: "村里", sortable: false },
      // { text: "有意願", value: "有意願", sortable: true, align: "center" },
      // { text: "需接送", value: "需接送", sortable: true, align: "center" },
      // { text: "需到府", value: "需到府", sortable: true, align: "center" },
      { text: "接種劑別", value: "接種劑別", sortable: true },
      { text: "最近接種疫苗", value: "已接種疫苗", sortable: true },
      { text: "接種地點", value: "接種地點", sortable: true },
      { text: "接種時間", value: "接種時間", sortable: true },
      { text: "接種疫苗", value: "接種疫苗", sortable: true },
      // { text: "已通知", value: "已通知", sortable: true, align: "center" },
      { text: "已報到", value: "已報到", sortable: true, align: "center" },
      { text: "報到時間", value: "報到時間", sortable: true, align: "center" },
      { text: "報到號碼", value: "報到號碼", sortable: true, align: "center" },
      {
        text: "醫生不同意",
        value: "醫生不同意",
        sortable: true,
        align: "center",
      },
    ],
    permit: {
      btnSystemLog: false,
      changeDistrict: true,
      inlineEdit: true,
      btnAssign: true,
      btnCancelReservation: true,
      btnOnSiteAssignDialog: true,
      btnSetHospital: true,
      btnAddCitizen: true,
      btnPrintContact: true,
      btnPrintNotice: false,
      btnNIIS: true,
      btnReserveReport: true,
      btnListImport: false,
      btnNiisImport: true,
      btnPopulationImport: true,
      btnHospitalImport: false,
      btnListDelete: false,
      btnPrintAgreement: true,
    },
  },
  衛生所: {
    headers: [
      { text: "序號", value: "序號", sortable: false },
      { text: "姓名", value: "姓名", sortable: false },
      { text: "身分證字號", value: "身分證字號", sortable: false },
      { text: "出生年月日", value: "出生年月日", sortable: false },
      { text: "電話", value: "電話", sortable: false },
      // { text: "行政區", value: "行政區", sortable: false },
      // { text: "村里", value: "村里", sortable: false },
      { text: "接種劑別", value: "接種劑別", sortable: true },
      { text: "最近接種疫苗", value: "已接種疫苗", sortable: true },
      { text: "接種地點", value: "接種地點", sortable: true },
      { text: "接種時間", value: "接種時間", sortable: true },
      { text: "接種疫苗", value: "接種疫苗", sortable: true },
      { text: "已報到", value: "已報到", sortable: true, align: "center" },
      { text: "報到時間", value: "報到時間", sortable: true, align: "center" },
      { text: "報到號碼", value: "報到號碼", sortable: true, align: "center" },
      {
        text: "醫生不同意",
        value: "醫生不同意",
        sortable: true,
        align: "center",
      },
    ],
    permit: {
      btnSystemLog: false,
      changeDistrict: false,
      inlineEdit: true,
      btnAssign: false,
      btnCancelReservation: true,
      btnOnSiteAssignDialog: true,
      btnSetHospital: true,
      btnAddCitizen: false,
      btnPrintContact: true,
      btnPrintNotice: true,
      btnNIIS: true,
      btnReserveReport: true,
      btnListImport: false,
      btnNiisImport: false,
      btnPopulationImport: false,
      btnHospitalImport: false,
      btnListDelete: false,
      btnPrintAgreement: true,
    },
  },
  大型接種站: {
    headers: [
      { text: "序號", value: "序號", sortable: false },
      { text: "姓名", value: "姓名", sortable: false },
      { text: "身分證字號", value: "身分證字號", sortable: false },
      { text: "出生年月日", value: "出生年月日", sortable: false },
      { text: "電話", value: "電話", sortable: false },
      // { text: "行政區", value: "行政區", sortable: false },
      // { text: "村里", value: "村里", sortable: false },
      { text: "接種劑別", value: "接種劑別", sortable: true },
      { text: "最近接種疫苗", value: "已接種疫苗", sortable: true },
      { text: "接種地點", value: "接種地點", sortable: true },
      { text: "接種時間", value: "接種時間", sortable: true },
      { text: "接種疫苗", value: "接種疫苗", sortable: true },
      { text: "已報到", value: "已報到", sortable: true, align: "center" },
      { text: "報到時間", value: "報到時間", sortable: true, align: "center" },
      { text: "報到號碼", value: "報到號碼", sortable: true, align: "center" },
      {
        text: "醫生不同意",
        value: "醫生不同意",
        sortable: true,
        align: "center",
      },
    ],
    permit: {
      btnSystemLog: false,
      changeDistrict: false,
      inlineEdit: true,
      btnAssign: false,
      btnCancelReservation: false,
      btnOnSiteAssignDialog: true,
      btnSetHospital: false,
      btnAddCitizen: false,
      btnPrintContact: true,
      btnPrintNotice: false,
      btnNIIS: true,
      btnReserveReport: false,
      btnListImport: false,
      btnNiisImport: false,
      btnPopulationImport: false,
      btnHospitalImport: false,
      btnListDelete: false,
      btnPrintAgreement: true,
    },
  },
  醫事單位: {
    headers: [
      { text: "序號", value: "序號", sortable: false },
      { text: "姓名", value: "姓名", sortable: false },
      { text: "身分證字號", value: "身分證字號", sortable: false },
      { text: "出生年月日", value: "出生年月日", sortable: false },
      { text: "電話", value: "電話", sortable: false },
      // { text: "行政區", value: "行政區", sortable: false },
      // { text: "村里", value: "村里", sortable: false },
      { text: "接種劑別", value: "接種劑別", sortable: true },
      { text: "最近接種疫苗", value: "已接種疫苗", sortable: true },
      { text: "接種地點", value: "接種地點", sortable: true },
      { text: "接種時間", value: "接種時間", sortable: true },
      { text: "接種疫苗", value: "接種疫苗", sortable: true },
      { text: "已報到", value: "已報到", sortable: true, align: "center" },
      { text: "報到時間", value: "報到時間", sortable: true, align: "center" },
      { text: "報到號碼", value: "報到號碼", sortable: true, align: "center" },
      {
        text: "醫生不同意",
        value: "醫生不同意",
        sortable: true,
        align: "center",
      },
    ],
    permit: {
      btnSystemLog: false,
      changeDistrict: false,
      inlineEdit: true,
      btnAssign: false,
      btnCancelReservation: false,
      btnOnSiteAssignDialog: true,
      btnSetHospital: false,
      btnAddCitizen: false,
      btnPrintContact: true,
      btnPrintNotice: false,
      btnNIIS: true,
      btnReserveReport: false,
      btnListImport: false,
      btnNiisImport: false,
      btnPopulationImport: false,
      btnHospitalImport: false,
      btnListDelete: false,
      btnPrintAgreement: true,
    },
  },
};
