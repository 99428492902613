<template>
  <v-dialog v-model="propModel" max-width="300px" persistent>
    <v-card>
      <v-card-title>列印同意書</v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-text-field
              v-model="身分證字號"
              label="身分證字號"
              outlined
              dense
              hide-details
              clearable
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-checkbox
              v-model="continuous"
              label="啟用報到列印"
              outlined
              hide-details
            >
            </v-checkbox>
          </v-col>
        </v-row>
        <v-row v-if="!$store.state.printer.live && continuous">
          <v-col>
            <v-alert dense dark color="warning"
              >若要啟用「全自動」列印，先確定已接上「全自動」列印套件，並已授權
              <a class="white--text" target="_blank" href="https://192.168.22.1"
                >https://192.168.22.1</a
              >
              的私人連線。</v-alert
            >
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" text @click="reset"> 關閉 </v-btn>
        <v-btn color="blue darken-1" text @click="print">列印</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { vaccineType } from "@/config";
export default {
  name: "PrintAgreement",
  props: ["value"],
  data: () => ({
    身分證字號: "",
    vaccineType,
    continuous: false,
  }),
  computed: {
    propModel: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  created() {
    this.continuous = this.$store.state.printer.continuous;
  },
  methods: {
    async print() {
      if (!this.身分證字號) {
        alert("身分證字號必須填寫！");
        return false;
      }

      let citizen = (
        await this.$axios.get("/citizen", {
          params: { 身分證字號: this.身分證字號 },
        })
      ).data;
      if (citizen) {
        this.$store.commit("printAgreement", citizen);
      } else {
        this.$toast.error(`該身分證不在造冊中！`, { timeout: 5000 });
      }

      this.reset();
    },
    reset() {
      this.身分證字號 = "";
      this.propModel = false;
    },
  },
  watch: {
    continuous(value) {
      this.$store.commit("printAgreementContinuous", value);
    },
  },
};
</script>

<style>
</style>