<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
    <!-- <img src="http://localhost:8082/t.png" alt="" /> -->
    <div v-if="print">
      <Paper ref="print"></Paper>
    </div>
    <!-- <div v-if="printKid">
      <PaperKid ref="printKid"></PaperKid>
    </div>
    <div v-if="printNovavax">
      <PaperNovavax ref="printNovavax"></PaperNovavax>
    </div> -->
  </v-app>
</template>

<script>
import Paper from "@/components/Paper.vue";
import PaperKid from "@/components/PaperKid.vue";
import PaperNovavax from "@/components/PaperNovavax.vue";

export default {
  name: "App",
  components: { Paper, PaperKid, PaperNovavax },
  data: () => ({
    print: false,
    // printKid: false,
    // printNovavax: false,
    //
  }),
  async created() {
    this.$store.commit("printAgreement", null);
    this.touchPrinter();
    await this.$recaptchaLoaded();
    const recaptcha = this.$recaptchaInstance;
    recaptcha.hideBadge();
  },
  computed: {
    printerServer() {
      let base = `https://192.168.22.1`;
      // let base = `http://192.168.1.200:7000`;
      if (this.$store.state.printer.citizen) {
        return `${base}/${this.$store.state.printer.citizen.身分證字號 ||
          "-"}/${this.$store.state.printer.citizen.姓名 || "-"}/${this.$store
          .state.printer.citizen.出生年月日 ||
          "-"}/${this.$store.state.printer.citizen.電話
          .replace("/", ",")
          .replace(`\\`, ",") || "-"}/${this.$store.state.printer.citizen
          .縣市 || "-"}/${this.$store.state.printer.citizen.行政區 ||
          "-"}/${this.$store.state.printer.citizen.地址 ||
          "-"}/${Math.random()}`;
      } else {
        return `${base}/`;
      }
    },
  },
  methods: {
    async touchPrinter() {
      this.$store.commit("setPrinterStatus", false);
      // try {
      //   await this.$axios.get(this.printerServer, { alone: true });
      //   this.$store.commit("setPrinterStatus", true);
      //   this.$toast.success(`自動列印套件已連接成功！`, {
      //     timeout: 3000,
      //   });
      // } catch (error) {
      //   this.$store.commit("setPrinterStatus", false);
      // }
    },
  },
  watch: {
    async "$store.state.printer.citizen"(citizen) {
      if (citizen) {
        if (this.$store.state.printer.live) {
          this.touchPrinter();
          this.$toast.success(`「${citizen.姓名}」送出列印！`, {
            timeout: 5000,
          });
        } else {
          this.print = true;
          this.$nextTick(() => {
            this.$print(this.$refs.print);
            this.print = false;
          });
        }
      }
    },
    // async "$store.state.printer.citizenKid"(citizen) {
    //   if (citizen) {
    //     this.printKid = true;
    //     this.$nextTick(() => {
    //       this.$print(this.$refs.printKid);
    //       this.printKid = false;
    //     });
    //   }
    // },
    // async "$store.state.printer.citizenNovavax"(citizen) {
    //   if (citizen) {
    //     this.printNovavax = true;
    //     this.$nextTick(() => {
    //       this.$print(this.$refs.printNovavax);
    //       this.printNovavax = false;
    //     });
    //   }
    // },
  },
};
</script>
<style lang="scss">
html {
  overflow-y: auto !important;
}
.pointer {
  cursor: pointer;
}
.Vue-Toastification__container {
  // max-width: 800px !important;
  // width: auto !important;
}
.Vue-Toastification__toast {
  // max-width: 800px !important;
}
.toast-text {
  font-size: 22px !important;
  width: 100% !important;
  line-height: 30px !important;
}
</style>
