<template>
  <v-dialog v-model="propModel" max-width="800px" persistent>
    <v-card>
      <v-card-title>
        <span class="text-h5">設定醫事地點</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="3">
              <v-select
                v-model="行政區"
                :items="行政區List"
                label="行政區/工作人員分類"
                dense
                hide-details
                outlined
                :disabled="!isManager || !!$store.state.user.醫事單位"
              ></v-select>
            </v-col>
            <v-col>
              <v-combobox
                dense
                outlined
                v-model="selectedHospital"
                item-text="名稱"
                :items="hospitals"
                hide-details
                label="請選擇醫事單位"
                :disabled="!!$store.state.user.醫事單位 && !isManager"
              ></v-combobox>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-file-input
                v-model="file"
                prepend-icon=""
                accept=".csv"
                label="場次時段批次檔"
                outlined
                dense
                hint="<a href='/場次時段批次範例檔.csv'>場次時段批次範例檔.csv</a>"
                persistent-hint
              >
                <template v-slot:message="{ message, key }">
                  <span v-html="message"></span>
                </template>
              </v-file-input>
            </v-col>
            <v-col>
              <v-btn color="success" @click="dataImport()">上傳批次檔</v-btn>
            </v-col>
          </v-row>
          <template v-if="selectedHospital">
            <v-row>
              <v-col cols="12" class="text-h6">地點資訊</v-col>
              <v-col cols="12">
                <v-switch class="mt-0" dense hide-details v-model="form.開啟">
                  <template v-slot:label>
                    <span class="input__label">
                      目前可預約狀態:
                      <span
                        v-if="form.開啟"
                        class="success--text font-weight-bold"
                        >開啟</span
                      >
                      <span v-else class="error--text font-weight-bold"
                        >暫停</span
                      >
                    </span>
                  </template>
                </v-switch>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="form.施打站代碼"
                  label="施打站代碼"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="form.衛生所醫事機構代碼"
                  label="衛生所醫事機構代碼"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="form.電話"
                  label="電話"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-select
                  v-model.number="form.年齡限制"
                  :items="[
                    {
                      text: '不限制',
                      value: 0,
                    },
                    {
                      text: '5 歲以上',
                      value: 5,
                    },
                    {
                      text: '12 歲以上',
                      value: 12,
                    },
                    {
                      text: '18 歲以上',
                      value: 18,
                    },
                  ]"
                  label="年齡限制"
                  outlined
                  dense
                  hide-details
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="text-h6">服務時段 </v-col>
            </v-row>
            <v-row>
              <v-col class="text-center">
                <v-date-picker
                  locale="zh-tw"
                  width="350"
                  :elevation="3"
                  no-title
                  v-model="pickDate"
                  :events="serviceDate"
                  event-color="green lighten-1"
                ></v-date-picker>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="text-center" v-if="noSection"
                >目前尚無服務時段</v-col
              >
            </v-row>
            <v-col class="text-center my-2">
              <v-btn color="primary" @click="plus">增加一筆</v-btn>
            </v-col>
            <v-card
              v-for="(section, index) of form.服務時段"
              :key="index"
              class="mt-1 mb-7 pt-6"
              v-if="
                dayjs(section.日期).format('YYYY-MM-DD') ==
                  dayjs(pickDate).format('YYYY-MM-DD')
              "
            >
              <v-row class="mx-0 px-2">
                <v-col :cols="3"
                  ><v-text-field
                    :disabled="section.已配發 != 0"
                    :value="section.開始時間"
                    @change="inputTime($event, section, '開始時間')"
                    label="開始時間"
                    prepend-icon="mdi-clock-time-nine-outline"
                    dense
                    placeholder="例: 09:00"
                    hide-details
                  ></v-text-field
                ></v-col>
                <v-col :cols="3"
                  ><v-text-field
                    :disabled="section.已配發 != 0"
                    :value="section.結束時間"
                    @change="inputTime($event, section, '結束時間')"
                    label="結束時間"
                    prepend-icon="mdi-clock-time-seven"
                    dense
                    placeholder="例: 19:00"
                    hide-details
                  ></v-text-field
                ></v-col>
                <v-col :cols="2"
                  ><v-text-field
                    v-model.number="section.配額上限"
                    @change="dirty(section)"
                    label="配額上限"
                    prepend-icon="mdi-account-multiple-plus-outline"
                    dense
                    placeholder="例: 50"
                    hide-details
                  ></v-text-field
                ></v-col>
                <v-col :cols="2">
                  <v-text-field
                    v-model.number="section.已配發"
                    @change="dirty(section)"
                    label="已配發"
                    prepend-icon="mdi-account-check-outline"
                    disabled
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col :cols="2">
                  <v-btn
                    text
                    :disabled="section.已配發 != 0"
                    color="error"
                    @click="remove(index, section)"
                  >
                    刪除
                  </v-btn>
                </v-col>
                <v-col :cols="3">
                  <v-select
                    v-model="section.疫苗種類"
                    :items="vaccineType"
                    prepend-icon="mdi-flask-outline"
                    label="疫苗種類"
                    dense
                    hide-details
                  ></v-select>
                </v-col>
                <!-- <v-col :cols="4">
                  <v-select
                    v-model="section.劑量"
                    :items="vaccineDose"
                    prepend-icon="mdi-flask-outline"
                    label="劑量"
                    dense
                    hide-details
                  ></v-select>
                </v-col> -->
                <v-col :cols="7">
                  <v-text-field
                    :value="section.服務村里.join(', ')"
                    :disabled="section.已配發 != 0 && !isManager"
                    @blur="updateServe($event, section)"
                    label="服務村里"
                    dense
                    placeholder="尚無任何服務村里"
                    hint="留空白，或逗號分隔"
                    persistent-hint
                  ></v-text-field>
                </v-col>
                <v-col :cols="2">
                  <v-checkbox
                    v-model="section.特別場"
                    :label="`特別場`"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-card>
          </template>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          text
          @click="
            reset();
            propModel = false;
          "
        >
          關閉
        </v-btn>
        <v-btn color="blue darken-1" text @click="save"> 儲存 </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { vaccineType, vaccineDose } from "@/config.js";
import _ from "lodash";
import dayjs from "dayjs";
export default {
  name: "Hospital",
  data: () => ({
    removeList: [],
    dayjs,
    vaccineType,
    vaccineDose,
    hospitals: [],
    selectedHospital: null,
    form: {},
    today: dayjs().format("YYYY-MM-DD"),
    行政區List: [],
    縣市List: [],
    行政區: "",
    pickDate: dayjs().format("YYYY-MM-DD"),
    file: null,
  }),
  props: ["value", "params", "isManager", "isAdmin"],
  created() {
    this.行政區List = _.cloneDeep(this.params.行政區List);
    // this.行政區List.shift();
    this.縣市List = _.cloneDeep(this.params.縣市List);
    this.行政區 = this.params.行政區;
  },
  computed: {
    noSection() {
      return (
        this.form.服務時段.filter((section) => section.日期 == this.pickDate)
          .length == 0
      );
    },
    serviceDate() {
      return this.form.服務時段.map((section) => section.日期);
    },
    propModel: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    inputTime(value, section, key) {
      section.type = "dirty";
      section[key] = value
        .split(":")
        .map((time) => time.padStart(2, "0"))
        .join(":");
    },
    expire(date) {
      return dayjs(date) < dayjs(dayjs().format("YYYY-MM-DD"));
    },
    async save() {
      let dirty = this.form.服務時段
        .filter((section) => section.type)
        .concat(this.removeList);

      if (dirty.length) {
        dirty = dirty.map((section) => {
          section.名稱 = this.form.名稱;
          section.editor = this.$store.state.user.帳號;
          return section;
        });
        await this.$axios.post("/log/section", dirty);
      }

      this.form.服務時段 = this.form.服務時段.map((section) => {
        delete section.type;
        return section;
      });

      let data = _.pick(this.form, [
        "電話",
        "服務時段",
        "施打站代碼",
        "衛生所醫事機構代碼",
        "年齡限制",
        "開啟",
      ]);
      await this.$axios.patch("/hospital/" + this.form._id, data);
      await this.getHospitals();
      this.removeList = [];
      alert("修改完成");
    },
    updateServe(event, section) {
      section.type = "dirty";
      section.服務村里 = event.target.value
        .replace(/ /g, "")
        .split(",")
        .filter((item) => item.length);
    },
    dirty(section) {
      if (section.type != "new") {
        section.type = "dirty";
      }
    },
    remove(index, section) {
      if (section.type != "new") {
        section.type = "remove";
        this.removeList.push({ ...section });
      }

      this.form.服務時段.splice(index, 1);
    },
    plus() {
      this.form.服務時段.unshift({
        type: "new",
        疫苗種類: "",
        日期: this.pickDate,
        開始時間: "",
        結束時間: "",
        服務村里: [],
        配額上限: 0,
        已配發: 0,
      });
    },
    reset() {
      this.selectedHospital = null;
      this.removeList = [];
      this.form = {};
      this.file = null;
    },
    async getHospitals() {
      this.hospitals = (
        await this.$axios.get("/hospitals", {
          params: _.pickBy(
            { 縣市: this.params.縣市, 行政區: this.行政區 },
            _.identity
          ),
        })
      ).data.filter((hospital) => hospital.名稱);
      if (this.$store.state.user.醫事單位) {
        this.selectedHospital = this.hospitals.find(
          (hospital) => hospital.名稱 == this.$store.state.user.醫事單位
        );
      }
    },
    async dataImport() {
      if (this.file) {
        let formData = new FormData();
        formData.append("file", this.file, this.file.name);
        formData.append("行政區", this.行政區);
        let result = (
          await this.$axios.post("/importHospitalSection", formData)
        ).data;
        alert(`總共異動 ${result.total}筆`);
        this.file = null;
        this.getHospitals();
      }
    },
  },
  watch: {
    value(open) {
      if (open) this.getHospitals();
      else this.reset();
    },
    selectedHospital(hospital) {
      this.form = _.cloneDeep(hospital);
    },
    行政區() {
      this.getHospitals();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-date-picker-table {
  margin-bottom: -15px;
}
</style>
