import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    login: false,
    service: false,
    user: {},
    selectedState: {},
    scan: "",
    printer: {
      continuous: false,
      citizen: null,
      citizenKid: null,
      live: false,
    },
    hospitalCodes: [],
  },
  mutations: {
    setHospitals(state, hospitals) {
      let hospitalCodes = {};
      hospitals
        .filter((hospital) => !!hospital.衛生所醫事機構代碼)
        .forEach((hospital) => {
          hospitalCodes[hospital.名稱] = hospital.衛生所醫事機構代碼;
        });
      state.hospitalCodes = hospitalCodes;
    },
    setPrinterStatus(state, value) {
      state.printer.live = value;
    },
    printAgreement(state, citizen) {
      state.printer.citizen = citizen;
    },
    // printAgreementKid(state, citizen) {
    //   state.printer.citizenKid = citizen;
    // },
    // printAgreementNovavax(state, citizen) {
    //   state.printer.citizenNovavax = citizen;
    // },
    printAgreementContinuous(state, value) {
      state.printer.continuous = value;
    },
    scan(state, text) {
      state.scan = text;
    },
    login(state, user) {
      state.login = true;
      state.user = user;
      state.selectedState = {};
    },
    service(state, user) {
      state.login = false;
      state.service = true;
      state.user = user;
    },
    logout(state) {
      state.login = false;
      state.service = false;
      state.user = {};
    },
    keepState(state, selectedState) {
      state.selectedState = selectedState;
    },
  },
  actions: {},
  modules: {},
  plugins: [createPersistedState()],
});
